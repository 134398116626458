import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParamsContext } from "../../../../../context/juno/ParamsContext";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Slider } from "@mui/material";
import Collapse from "@mui/material/Collapse";

const Style = () => {
  const { t } = useTranslation();
  const { styleWeight, setStyleWeight, style } = useParamsContext();
  const params = new URLSearchParams(window.location.search);
  const aiEngine = params.get("ai_engine");
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (
      (aiEngine === "Flux1DevDepthLoRA" || aiEngine === "Flux1DevCannyLoRA" || aiEngine === "Flux1DevFill") &&
      style[aiEngine] != "GENERAL"
    ) {
      setOpen(true);
    } else {
      if (aiEngine === "FluxJuno") {
        setOpen(true);
      } else {
        setOpen(false);
      }
    }
  }, [aiEngine, style["Flux1DevDepthLoRA"], style["Flux1DevCannyLoRA"], style["Flux1DevFill"]]);

  return (
    <>
      <Collapse in={open}>
        <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mb: 1 }}>
            <Typography variant={"body2"}>{t("juno.input.style.weight")}</Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }} gap={2}>
            <Slider
              value={styleWeight[aiEngine!]}
              onChange={(e, value) => setStyleWeight({ ...styleWeight, [aiEngine!]: value as number })}
              min={0}
              max={1.5}
              step={0.1}
              marks={[{ value: 0.8, label: "" }]}
            />
            <Box
              sx={{
                width: "120px",
                bgcolor: "rgba(0, 0, 0, 0.1)",
                px: 1,
                py: 0.5,
                justifyContent: "center",
                display: "flex",
                borderRadius: "5px",
              }}
            >
              <Typography variant={"body2"}>{styleWeight[aiEngine!]}</Typography>
            </Box>
          </Box>
        </Box>
      </Collapse>
    </>
  );
};
export default Style;
