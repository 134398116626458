const junoVideo = {
  title: "VIDEO GENERATOR",
  menu: {
    t2v: "テキストから動画を生成",
    i2v: "画像から動画を生成",
    v2v: "動画から動画を生成",
    library: "ライブラリ",
  },
  ai: {
    select: "AIモデルを選択してください",
    model: "モデル",
    runway: {
      title: "RunwayML",
      description: "【高速】AIと機械学習を活用して動画制作プロセスを簡素化し、プロから初心者まで幅広いユーザー向け。",
      model: {
        GEN3_ALPHA_TURBO: "Gen3 Alpha Turbo",
      },
    },
    kling: {
      title: "KLING AI",
      description: "【低速】KLING AIは、高度な物理シミュレーションと3D技術を活用したリアルで自然な動きの動画を生成。",
      model: {
        V1_0_STANDARD: "スタンダード v1.0",
        V1_0_PRO: "プロ v1.0",
        V1_5_PRO: "プロ v1.5",
      },
    },
    luma: {
      title: "Luma",
      description: "【高速】Luma Dream Machineは、テキストや画像から高品質でリアルな動画を生成するAIモデル。",
      model: {
        V1_5: "Dream MACHINE v1.5",
      },
    },
    hailuo: {
      title: "Hailuo AI",
      description: "【低速】Hailuo AI/Minimaxは、高品質な動画生成と人物の動きの正確な再現に優れているとされています。",
      model: {
        MINI_MAX: "MINI MAX",
      },
    },
    haiper: {
      title: "Haiper AI",
      description: "【低速】Haiper AIは、日本人の顔やポーズを自然に再現する能力に優れています",
      model: {
        V2_0: "Haiper V2.0",
      },
    },
  },
  params: {
    title: "動画設定",
    upload: "画像アップロード",
    duration: "動画の長さ",
    aspect: "アスペクト比",
    loop: "ループ",
    input: {
      label: "プロンプト",
      placeholder: "動画のプロンプトを入力してください（英語推奨）",
      warning:
        "※ 性的または暴力的なコンテンツ、ハラスメント、差別、不適切な表現、その他法に違反する動画は生成できません",
    },
    submit: "動画を生成",
  },
  myVideo: "マイビデオ",
  processing: "動画生成中...\n通常5分以内に完了します",
  sampleVideo: "サンプル動画",
  error: {
    generate: "動画の生成に失敗しました。\nポリシー違反がないか確認し、\n時間をおいて再度お試しください。",
    required: {
      image: "画像をアップロードしてください。",
      prompt: "プロンプトを入力してください。",
    },
    max: {
      prompt: "プロンプトは512文字以内で入力してください。",
    },
  },
  startGenerating: "動画生成リクエストをキューに追加しました。",
  videoMenu: {
    download: "ダウンロード",
    delete: "削除",
  },
  message: {
    deleted: "動画を削除しました。",
  },
};
export default junoVideo;
