import React from "react";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import PhotoSizeSelectLargeIcon from "@mui/icons-material/PhotoSizeSelectLarge";
import ImageIcon from "@mui/icons-material/Image";
import { useTranslation } from "react-i18next";
import FontDownloadIcon from "@mui/icons-material/FontDownload";
import BoltIcon from "@mui/icons-material/Bolt";
import BlurOnIcon from "@mui/icons-material/BlurOn";
import { aiEngineColor } from "../../../../utils/aiEngineColor";
import { useCustomNavigate } from "../../../../hooks/useCustomNavigate";
import FormatShapesIcon from "@mui/icons-material/FormatShapes";
import RouteIcon from "@mui/icons-material/Route";
import AltRouteIcon from "@mui/icons-material/AltRoute";
import { Tooltip } from "@mui/material";
import BrushIcon from "@mui/icons-material/Brush";

interface ActionLabelsProps {
  params: any;
  onClose?: any;
}

const ActionLabels = ({ params, onClose }: ActionLabelsProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useCustomNavigate();

  if (!params) return null;

  const handleNavigate = () => {
    navigate(`/juno?ai_engine=${params["ai_engine"]}`);
    if (onClose) onClose();
  };

  const isControlNet = (ai_engine: string) => {
    return ["Flux1DevDepthLoRA", "Flux1DevCannyLoRA", "Flux1ProCanny", "Flux1ProDepth"].includes(ai_engine);
  };

  const isVariations = (ai_engine: string) => {
    return ["Flux11ProUltraRedux", "Flux11ProRedux", "Flux1DevRedux", "Flux1SchnellRedux"].includes(ai_engine);
  };

  return (
    <>
      <Typography
        sx={{
          py: 1,
          px: 2,
          backgroundColor: theme.palette.grey[800],
          color: theme.palette.grey[50],
          display: "flex",
          borderRadius: 2,
          whiteSpace: "nowrap",
        }}
        variant={"body2"}
      >
        {params["action"] === "txt2img" ? (
          <FontDownloadIcon fontSize="small" sx={{ mr: 1 }} />
        ) : params["action"] === "img2img" ? (
          <ImageIcon fontSize="small" sx={{ mr: 1 }} />
        ) : params["action"] === "editor" ? (
          <BrushIcon fontSize="small" sx={{ mr: 1 }} />
        ) : (
          <PhotoSizeSelectLargeIcon fontSize="small" sx={{ mr: 1 }} />
        )}

        {t(`juno.menu.${params["action"]}`)}
      </Typography>

      {isControlNet(params["ai_engine"]) && (
        <Tooltip title={t("juno.input.category.controlNet")} arrow placement="top">
          <Typography
            sx={{
              py: 1,
              px: 2,
              backgroundColor: "#865f31",
              color: "#fff",
              display: "inline-block",
              borderRadius: 2,
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              maxWidth: "100%",
            }}
            variant={"body2"}
          >
            <RouteIcon fontSize="small" sx={{ mr: 1, float: "inline-start" }} />
            ControlNet
          </Typography>
        </Tooltip>
      )}

      {isVariations(params["ai_engine"]) && (
        <Tooltip title={t("juno.input.category.vary")} arrow placement="top">
          <Typography
            sx={{
              py: 1,
              px: 2,
              backgroundColor: "#463186",
              color: "#fff",
              display: "inline-block",
              borderRadius: 2,
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              maxWidth: "100%",
            }}
            variant={"body2"}
          >
            <AltRouteIcon fontSize="small" sx={{ mr: 1, float: "inline-start" }} />
            VARIATIONS
          </Typography>
        </Tooltip>
      )}

      {params["style"]?.startsWith("vector") && (
        <Typography
          sx={{
            py: 1,
            px: 2,
            backgroundColor: "#d0d0d0",
            color: theme.palette.grey[700],
            display: "inline-block",
            borderRadius: 2,
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            maxWidth: "100%",
          }}
          variant={"body2"}
        >
          <FormatShapesIcon fontSize="small" sx={{ mr: 1, float: "inline-start" }} />
          SVG
        </Typography>
      )}

      <Typography
        sx={{
          py: 1,
          px: 2,
          backgroundColor: aiEngineColor(params["ai_engine"]),
          color: theme.palette.grey[50],
          display: "flex",
          borderRadius: 2,
          whiteSpace: "nowrap",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => handleNavigate()}
        variant={"body2"}
      >
        <BoltIcon fontSize="small" sx={{ mr: 1 }} />
        {t(`juno.input.engine.${params["ai_engine"]}`)}
      </Typography>

      {params["model"]?.name && (
        <Typography
          sx={{
            py: 1,
            px: 2,
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.grey[50],
            display: "inline-block",
            borderRadius: 2,
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            maxWidth: "100%",
          }}
          variant={"body2"}
        >
          <BlurOnIcon fontSize="small" sx={{ mr: 1, float: "inline-start" }} />
          {params["model"]?.name}
        </Typography>
      )}
    </>
  );
};

export default ActionLabels;
