const browsing = {
  title: "ブラウジング",
  history: "履歴",
  preTitle: "Browsing with",
  subtitle: "Tenak AI はWeb情報を活用し、最新かつ正確な回答を提供します。",
  browsingMode: "WEBアクセス",
  browsingModeDescription: "URLや自動検索を行い、最新の情報に対応した回答を生成します。",
  search: "Search",
  searchHelp:
    "検索結果の言語や地域が異なる場合は、設定ページから最適化できます。設定ページにアクセスして、お好みに合わせて設定を変更してください。それにより、より適切な情報を提供できるようになります。",
  send: "送信キー (Ctrl + Enter)",
  searchPlaceholder: "メッセージを入力して下さい。",
  stopGenerating: "生成を停止",
  query: "メッセージ",
  answer: "回答",
  searchMore: "Google でもっと検索する",
  clear: "会話履歴をクリア",
  delete: "ワークスペースを削除",
  customize: "ワークスペースをカスタマイズ",
  new: "NEW",
  displayHistoryDescribe: "30件まで表示されます。",
  noHistory: "会話履歴はありません。",
  addWorkspace: "ワークスペースを追加",
  createWorkspace: "ワークスペースを作成しました。",
  deleteWorkspace: {
    title: "ワークスペースを削除しますか？",
    description:
      "ワークスペースを削除すると、ワークスペース内のすべてのデータが削除されます。この操作は取り消せません。",
    success: "ワークスペースが削除されました。",
  },
  deleteHistory: {
    title: "会話履歴を削除しますか？",
    description: "会話履歴を削除します。この操作は取り消せません。",
  },
  customizeWorkspace: {
    title: "ワークスペースのカスタマイズ",
    description: "ワークスペースのタイトルとTenak AIの振る舞いをカスタマイズできます。",
    workspaceTitle: "ワークスペースのタイトル",
    workspaceTitleDescription: "タイトルをメニューに反映します。",
    instructionTitle: "Tenak AI の振る舞い",
    instructionDescription: "役割や回答形式など、Tenak AIの振る舞いをカスタマイズできます。",
    instructionTemplate:
      "# 役割 \n- あなたは優秀なAIアシスタントです。 \n \n# 制約\n- 簡潔に答えてください\n\n# 出力形式\n- 常に日本語で答えよ",
    instructionPlaceholder: "あなたは優秀なAIアシスタントです。",
    setTemplate: "サンプルテンプレートを適用",
  },
  article: "回答から記事を生成",
  mindMap: "回答からマインドマップを生成",
  summary: "回答を要約",
  error: {
    search: "検索結果が見つからないか、サーバーが高負荷のため検索結果を取得できませんでした。",
  },
  url: {
    title: "参照URL",
    result: {
      success: "URLを参照しました。",
      error: "URLの参照に失敗しました。直接テキストを挿入してください。",
    },
  },
  sidebar: {
    preSearchTitle: "検索結果",
    preSearchDescription: "WEBアクセスをONにすると、検索結果が表示され、回答の参考にします。",
  },
  caution: "AIは間違った回答をすることがあります。必ず二重チェックをしてください。",
};
export default browsing;
