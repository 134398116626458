import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Collapse from "@mui/material/Collapse";
import * as React from "react";
import { useArticleContext } from "../../../../context/ArticleContext";
import { useTranslation } from "react-i18next";
import GoogleIcon from "@mui/icons-material/Google";
import Typography from "@mui/material/Typography";
import AddLinkIcon from "@mui/icons-material/AddLink";
import YouTubeIcon from "@mui/icons-material/YouTube";
import EditNoteIcon from "@mui/icons-material/EditNote";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { StyledTypography } from "../../../../utils/styledTypography";

export default function Source() {
  const { t } = useTranslation();
  const { srcType, setSrcType, content, setContent } = useArticleContext();

  return (
    <>
      <Box>
        <Box>
          <StyledTypography variant="body1" gutterBottom>
            {t("article.source")}
          </StyledTypography>
        </Box>
        <Box>
          <FormControl sx={{ mb: 1 }}>
            <RadioGroup row value={srcType ?? ""} onChange={(event) => setSrcType(event.target.value)}>
              <FormControlLabel value="google" control={<Radio />} label={t("article.srcType.google")} />
              <FormControlLabel value="url" control={<Radio />} label={t("article.srcType.url")} />
              <FormControlLabel value="youtube" control={<Radio />} label={t("article.srcType.youtube")} />
              <FormControlLabel value="text" control={<Radio />} label={t("article.srcType.text")} />
              <FormControlLabel value="" control={<Radio />} label={t("article.srcType.none")} />
            </RadioGroup>
          </FormControl>
          <Collapse in={srcType === "none"}>
            <Box display={"flex"} alignItems={"center"} mb={1}>
              <AutoAwesomeIcon sx={{ mr: 1 }} color={"secondary"} fontSize={"small"} />
              <Typography variant={"body2"}>{t("article.srcType.noneDescription")}</Typography>
            </Box>
          </Collapse>
          <Collapse in={srcType === "google"}>
            <Box display={"flex"} alignItems={"center"} mb={1}>
              <GoogleIcon sx={{ mr: 1 }} color={"secondary"} fontSize={"small"} />
              <Typography variant={"body2"}>{t("article.srcType.googleDescription")}</Typography>
            </Box>
          </Collapse>
          <Collapse in={srcType === "url"}>
            <>
              <Box display={"flex"} alignItems={"center"} mb={1}>
                <AddLinkIcon sx={{ mr: 1 }} color={"secondary"} fontSize={"small"} />
                <Typography variant={"body2"}>{t("article.srcType.urlDescription")}</Typography>
              </Box>
              <TextField
                label={t("article.srcType.url")}
                placeholder={t("article.srcType.urlPlaceholder")}
                autoComplete={"off"}
                type={"url"}
                variant="standard"
                value={content ?? ""}
                onChange={(e) => setContent(e.target.value)}
                fullWidth
                autoFocus
              />
            </>
          </Collapse>
          <Collapse in={srcType === "youtube"}>
            <>
              <Box display={"flex"} alignItems={"center"} mb={1}>
                <YouTubeIcon sx={{ mr: 1 }} color={"secondary"} fontSize={"small"} />
                <Typography variant={"body2"}>{t("article.srcType.youtubeDescription")}</Typography>
              </Box>
              <TextField
                label={t("article.srcType.youtube")}
                placeholder={t("article.srcType.youtubePlaceholder")}
                autoComplete={"off"}
                type={"url"}
                variant="standard"
                value={content ?? ""}
                onChange={(e) => setContent(e.target.value)}
                fullWidth
                autoFocus
              />
            </>
          </Collapse>
          <Collapse in={srcType === "text"}>
            <>
              <Box display={"flex"} alignItems={"center"} mb={2}>
                <EditNoteIcon sx={{ mr: 1 }} color={"secondary"} fontSize={"small"} />
                <Typography variant={"body2"}>{t("article.srcType.textDescription")}</Typography>
              </Box>
              <TextField
                label={t("article.srcType.text")}
                placeholder={t("article.srcType.textPlaceholder")}
                variant="outlined"
                multiline
                rows={8}
                value={content ?? ""}
                onChange={(e) => setContent(e.target.value)}
                fullWidth
                autoFocus
                inputProps={{ maxLength: 10000 }}
              />
              <Box display={"flex"} justifyContent={"flex-end"}>
                <Typography variant={"body2"}>{content.length}/10,000</Typography>
              </Box>
            </>
          </Collapse>
        </Box>
      </Box>
    </>
  );
}
