import React from "react";
import { useTranslation } from "react-i18next";
import { useParamsContext } from "../../../../../context/juno/ParamsContext";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

const ExpandPrompt = () => {
  const { t } = useTranslation();
  const { revisedPrompt, setRevisedPrompt } = useParamsContext();

  return (
    <>
      <Box>
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={revisedPrompt} />}
            label={t("juno.input.dallE3RevisedPrompt")}
            onChange={(_, checked) => setRevisedPrompt(checked)}
          />
        </FormGroup>
      </Box>
    </>
  );
};
export default ExpandPrompt;
