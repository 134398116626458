import React from "react";
import { useTranslation } from "react-i18next";
import { useParamsContext } from "../../../../../context/juno/ParamsContext";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { StyleFluxJuno, StyleRecraftV3 } from "../../../../../types/junoTypes";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { Divider } from "@mui/material";

const Style = () => {
  const { t } = useTranslation();
  const { style, setStyle } = useParamsContext();
  const params = new URLSearchParams(window.location.search);
  const aiEngine = params.get("ai_engine");

  const ideogramStyles: { label: string; value: "GENERAL" | "REALISTIC" | "DESIGN" | "ANIME" | "RENDER_3D" }[] = [
    { label: "general", value: "GENERAL" },
    { label: "realistic", value: "REALISTIC" },
    { label: "design", value: "DESIGN" },
    { label: "anime", value: "ANIME" },
    { label: "render3d", value: "RENDER_3D" },
  ];

  const recraftStyles = [
    {
      options: [{ value: "any", label: t("juno.input.style.recraft.any") }],
    },
    {
      options: [
        { value: "realistic_image", label: t("juno.input.style.recraft.realisticImage") },
        { value: "realistic_image/b_and_w", label: t("juno.input.style.recraft.realisticImageBW") },
        { value: "realistic_image/hard_flash", label: t("juno.input.style.recraft.realisticImageHardFlash") },
        { value: "realistic_image/hdr", label: t("juno.input.style.recraft.realisticImageHDR") },
        { value: "realistic_image/natural_light", label: t("juno.input.style.recraft.realisticImageNaturalLight") },
        { value: "realistic_image/studio_portrait", label: t("juno.input.style.recraft.realisticImageStudioPortrait") },
        { value: "realistic_image/enterprise", label: t("juno.input.style.recraft.realisticImageEnterprise") },
        { value: "realistic_image/motion_blur", label: t("juno.input.style.recraft.realisticImageMotionBlur") },
      ],
    },
    {
      options: [
        { value: "digital_illustration", label: t("juno.input.style.recraft.digitalIllustration") },
        { value: "digital_illustration/pixel_art", label: t("juno.input.style.recraft.digitalIllustrationPixelArt") },
        { value: "digital_illustration/hand_drawn", label: t("juno.input.style.recraft.digitalIllustrationHandDrawn") },
        { value: "digital_illustration/grain", label: t("juno.input.style.recraft.digitalIllustrationGrain") },
        {
          value: "digital_illustration/infantile_sketch",
          label: t("juno.input.style.recraft.digitalIllustrationInfantileSketch"),
        },
        {
          value: "digital_illustration/2d_art_poster",
          label: t("juno.input.style.recraft.digitalIllustration2DArtPoster"),
        },
        {
          value: "digital_illustration/2d_art_poster_2",
          label: t("juno.input.style.recraft.digitalIllustration2DArtPoster2"),
        },
        {
          value: "digital_illustration/handmade_3d",
          label: t("juno.input.style.recraft.digitalIllustrationHandmade3D"),
        },
        {
          value: "digital_illustration/hand_drawn_outline",
          label: t("juno.input.style.recraft.digitalIllustrationHandDrawnOutline"),
        },
        {
          value: "digital_illustration/engraving_color",
          label: t("juno.input.style.recraft.digitalIllustrationEngravingColor"),
        },
      ],
    },
    {
      options: [
        { value: "vector_illustration", label: t("juno.input.style.recraft.vectorIllustration") },
        { value: "vector_illustration/engraving", label: t("juno.input.style.recraft.vectorIllustrationEngraving") },
        { value: "vector_illustration/line_art", label: t("juno.input.style.recraft.vectorIllustrationLineArt") },
        {
          value: "vector_illustration/line_circuit",
          label: t("juno.input.style.recraft.vectorIllustrationLineCircuit"),
        },
        { value: "vector_illustration/linocut", label: t("juno.input.style.recraft.vectorIllustrationLinocut") },
      ],
    },
  ];

  const menuItems: JSX.Element[] = recraftStyles.reduce((acc: JSX.Element[], group, index) => {
    group.options.forEach((option) => {
      acc.push(
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
    if (index < recraftStyles.length - 1) {
      acc.push(<Divider key={`divider-${index}`} />);
    }
    return acc;
  }, []);

  return (
    <>
      {aiEngine == "DallE3" && (
        <>
          <FormControl fullWidth>
            <InputLabel id="ai-engine-select-label">{t("juno.input.style.title")}</InputLabel>
            <Select
              size={"small"}
              value={style.DallE3}
              label={t("juno.input.style.title")}
              onChange={(event) => {
                const newValue = event.target.value;
                if (newValue === "vivid" || newValue === "natural") {
                  setStyle({ ...style, DallE3: newValue });
                }
              }}
            >
              <MenuItem value="vivid">{t("juno.input.style.dalle3.vivid")}</MenuItem>
              <MenuItem value="natural">{t("juno.input.style.dalle3.natural")}</MenuItem>
            </Select>
          </FormControl>
        </>
      )}
      {["FluxJuno", "Flux1DevCannyLoRA", "Flux1DevDepthLoRA", "Flux1DevFill"].includes(aiEngine!) && (
        <>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mb: 2 }} gap={1}>
            <FormControl fullWidth>
              <InputLabel id="ai-engine-select-label">{t("juno.input.style.title")}</InputLabel>
              <Select
                size={"small"}
                value={style[aiEngine!]}
                label={t("juno.input.style.title")}
                onChange={(event) => {
                  const newValue = event.target.value as StyleFluxJuno;
                  setStyle({ ...style, [aiEngine!]: newValue });
                }}
              >
                {["Flux1DevCannyLoRA", "Flux1DevDepthLoRA", "Flux1DevFill"].includes(aiEngine!) && (
                  <MenuItem value="GENERAL">{t("juno.input.style.fluxJuno.general")}</MenuItem>
                )}
                <MenuItem value="ANIME">{t("juno.input.style.fluxJuno.anime")}</MenuItem>
                <MenuItem value="JAPANESE">{t("juno.input.style.fluxJuno.japanese")}</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </>
      )}
      {["Ideogram2", "Ideogram2Turbo", "Ideogram2Edit", "Ideogram2TurboEdit"].includes(aiEngine!) && (
        <Box>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} gap={1} mb={1}>
            <Typography variant={"body2"}>{t("juno.input.style.title")}</Typography>
          </Box>
          <Grid container spacing={2}>
            {ideogramStyles.map(({ label, value }) => (
              <Grid item key={label}>
                <Button
                  variant={style[aiEngine!] === value ? "outlined" : "text"}
                  onClick={() => setStyle({ ...style, [aiEngine!]: value })}
                >
                  <Typography variant="caption">{t(`juno.input.style.ideogram.${label}`)}</Typography>
                </Button>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      {["RecraftV3"].includes(aiEngine!) && (
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mb: 2 }} gap={1}>
          <FormControl fullWidth>
            <InputLabel id="ai-engine-select-label">{t("juno.input.style.title")}</InputLabel>
            <Select
              size={"small"}
              value={style[aiEngine!]}
              label={t("juno.input.style.title")}
              onChange={(event) => {
                const newValue = event.target.value as StyleRecraftV3;
                setStyle({ ...style, [aiEngine!]: newValue });
              }}
            >
              {menuItems}
            </Select>
          </FormControl>
        </Box>
      )}
    </>
  );
};
export default Style;
