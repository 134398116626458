import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { useCustomNavigate } from "../../hooks/useCustomNavigate";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

interface ProPlanModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  message?: string;
}

const ProPlanModal = ({ open, setOpen, message }: ProPlanModalProps) => {
  const { t } = useTranslation();
  const navigate = useCustomNavigate();

  const handleConfirm = (event) => {
    event.preventDefault();
    setOpen(false);
    navigate("/pricing");
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{t("common.proPlanDialog.title")}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">{message ? message : t("common.proPlanDialog.description")}</Typography>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
          <Button variant="outlined" color="primary" onClick={() => setOpen(false)} disableElevation sx={{ mr: 1 }}>
            {t("common.proPlanDialog.cancel")}
          </Button>
          <Button variant="contained" color="primary" onClick={handleConfirm} disableElevation>
            {t("common.proPlanDialog.confirm")}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ProPlanModal;
