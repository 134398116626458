import { Box } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { useCustomNavigate } from "../../../../hooks/useCustomNavigate";

interface HistoryParamsModelProps {
  params: any;
  setOpen: (open: boolean) => void;
}

const HistoryParamsModel = ({ params, setOpen }: HistoryParamsModelProps) => {
  const { t } = useTranslation();
  const [imgError, setImgError] = useState<{ [key: string]: boolean }>({});
  const navigate = useCustomNavigate();

  return (
    <Box sx={{ display: "flex", flexDirection: "row", mb: 4, width: "100%" }} gap={2}>
      {params?.["model"] && (
        <Box sx={{ display: "flex", flexDirection: "column", maxWidth: 250, width: "100%" }} gap={2}>
          <Typography variant={"caption"} color={"text.secondary"} component={"p"}>
            {t("juno.input.models")}:
          </Typography>
          <Box>
            <Box
              sx={{
                height: 64,
                position: "relative",
                overflow: "hidden",
                borderRadius: 2,
                width: "100%",
                cursor: "pointer",
                background: "linear-gradient(135deg, #7f5a83, #0d324d)",
                "&:hover img": {
                  transform: "scale(1.05)",
                },
                "&:hover": {
                  background: "linear-gradient(135deg, #0d324d, #7f5a83)",
                },
              }}
              onClick={() => {
                navigate(`/juno?page=1&model=${params?.["model"]["id"]}`);
                setOpen(false);
              }}
            >
              {!imgError[params?.["model"]["id"]] && (
                <img
                  src={params?.["model"]?.screenShot}
                  alt={params?.["model"]?.name}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    transition: "all 0.2s ease-in-out",
                    filter: "brightness(0.4)",
                  }}
                  onError={() =>
                    setImgError({
                      ...imgError,
                      [params?.["model"]["id"]]: true,
                    })
                  }
                />
              )}
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  px: 2,
                }}
              >
                <Typography
                  variant={"body2"}
                  component={"p"}
                  sx={{
                    color: "common.white",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textAlign: "center",
                    fontWeight: 600,
                  }}
                >
                  {params?.["model"].name}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      {params?.["lora"] && (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }} gap={2}>
          <Typography variant={"caption"} color={"text.secondary"} component={"p"}>
            {t("juno.input.loras")}:
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", gap: 2, width: "100%" }}>
            {Array.isArray(params?.["lora"]) &&
              params?.["lora"].map((lora, index) => (
                <Box
                  key={index}
                  sx={{
                    width: "100%",
                    height: 64,
                    position: "relative",
                    overflow: "hidden",
                    borderRadius: 2,
                    maxWidth: 250,
                    cursor: "pointer",
                    background: "linear-gradient(135deg, #7f5a83, #0d324d)",
                    "&:hover img": {
                      transform: "scale(1.05)",
                      transformOrigin: "center center",
                    },
                    "&:hover": {
                      background: "linear-gradient(135deg, #0d324d, #7f5a83)",
                    },
                  }}
                  onClick={() => {
                    navigate(`/juno?page=1&lora=${lora["id"]}`);
                    setOpen(false);
                  }}
                >
                  {!imgError[lora["id"]] && (
                    <img
                      src={lora.screenShot}
                      alt={lora.name}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: "center",
                        transition: "all 0.2s ease-in-out",
                        filter: "brightness(0.4)",
                      }}
                      onError={() =>
                        setImgError({
                          ...imgError,
                          [lora.id]: true,
                        })
                      }
                    />
                  )}

                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      p: 1,
                    }}
                  >
                    <Typography
                      variant={"body2"}
                      component={"p"}
                      sx={{
                        color: "common.white",
                        display: "-webkit-box",
                        WebkitLineClamp: 1,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textAlign: "center",
                        fontWeight: 600,
                      }}
                    >
                      {lora.name}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 1,
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        variant={"caption"}
                        component={"p"}
                        sx={{
                          color: "common.white",
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          textAlign: "center", // テキストを中央揃え
                        }}
                      >
                        {t("juno.input.loraStrength")}:
                      </Typography>
                      <Typography
                        variant={"caption"}
                        component={"p"}
                        sx={{
                          color: "common.white",
                          fontWeight: 600,
                        }}
                      >
                        {lora.strength}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default HistoryParamsModel;
