import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Fade, Grid, Pagination, TextField, Typography } from "@mui/material";
import { ColumnBox, ColumnCenteredBox, RowBox } from "../../../../utils/styledBox";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import { API_STATUS, useVideo } from "../../../../context/juno/VideoContext";
import ErrorIcon from "@mui/icons-material/Error";
import { alpha } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import Cookies from "js-cookie";
import { useAlert } from "../../../../context/AlertContext";
import { useLocation } from "react-router-dom";
import { getFileDateName } from "../../../../utils/utils";
import FontDownloadIcon from "@mui/icons-material/FontDownload";
import ImageIcon from "@mui/icons-material/Image";
type videoType = {
  uuid: string;
  status: number;
  params: any;
  url: string;
};

interface VideoListProps {
  grid?: number;
  size?: number;
}

const LatestVideoList = ({ grid, size }: VideoListProps) => {
  const ITEMS_PER_PAGE = size || 6;
  const { t } = useTranslation();
  const [videos, setVideos] = useState<videoType[]>([]);
  const { fetchVideoTrigger } = useVideo();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const { setAlert } = useAlert();

  const fetchVideos = async (page: number) => {
    try {
      const response = await fetch(`/api/v1/juno/videos?page_size=${ITEMS_PER_PAGE}&page=${page}`);
      const data = await response.json();
      if (data.status === "error") {
        console.error("ビデオの取得中にエラーが発生しました:", data.message);
        return [];
      } else {
        setTotalPages(data.total_pages);
        return data.videos as videoType[];
      }
    } catch (error) {
      console.error("フェッチエラー:", error);
      return [];
    }
  };

  // currentPageまたはfetchVideoTriggerが変更されたときにビデオを取得
  useEffect(() => {
    const loadVideos = async () => {
      const data = await fetchVideos(currentPage);
      setVideos(data);
    };

    loadVideos();
  }, [currentPage, fetchVideoTrigger]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  // PROCESSINGステータスのビデオが存在する場合、10秒ごとにポーリングしてステータスを更新
  useEffect(() => {
    const interval = setInterval(async () => {
      const processingVideos = videos.filter((video) => video.status === API_STATUS.PROCESSING);
      if (processingVideos.length > 0) {
        const data = await fetchVideos(currentPage);
        setVideos(data);
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [videos, currentPage]);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, videoUuid: string) => {
    setAnchorEl(event.currentTarget);
    setSelectedVideo(videoUuid);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedVideo(null);
  };

  const handleDeleteVideo = async (generatedVideoUuid: string) => {
    const csrftoken = Cookies.get("csrftoken");
    const headers = new Headers();
    if (csrftoken) {
      headers.append("X-CSRFToken", csrftoken);
    }
    headers.append("Content-Type", "application/json");
    handleMenuClose();

    try {
      const response = await fetch(`/api/v1/juno/generate-video`, {
        method: "DELETE",
        headers: headers,
        body: JSON.stringify({ generatedVideoUuid: generatedVideoUuid }),
      });

      if (!response.ok) {
        throw new Error("ビデオの削除に失敗しました");
      }

      // 削除後にビデオを再取得
      const data = await fetchVideos(currentPage);
      setVideos(data);
      setAlert("success", t("juno.video.message.deleted"));
    } catch (error) {
      console.error("削除エラー:", error);
    }
  };

  const handleVideoDownload = async (uuid: string) => {
    const blob = await fetch(`/api/v1/juno/generate-video?generatedVideoUuid=${uuid}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => response.blob());
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "juno-video-" + getFileDateName() + ".mp4";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const location = useLocation();

  return (
    <ColumnBox>
      {/* Header Section */}
      {location.pathname.split("/")[3]?.startsWith("video-generator") && (
        <RowBox mb={4} sx={{ width: "100%" }}>
          <VideoLibraryIcon color={"secondary"} sx={{ mr: 1 }} />
          <Typography variant="subtitle1">{t("juno.video.myVideo")}</Typography>
        </RowBox>
      )}

      {/* Video Grid */}
      <Grid container spacing={2} sx={{ minHeight: 50 }}>
        {videos &&
          videos.map((video, index) => (
            <Grid item key={video.uuid} xs={12} sm={6} md={grid ? grid : 4}>
              <Fade in={true} timeout={index * 500}>
                <Card elevation={0} sx={{ borderRadius: 2 }}>
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      overflow: "hidden",
                      height: 370,
                    }}
                  >
                    {video.status === API_STATUS.SUCCESS && (
                      <Box sx={{ width: "100%", height: 210 }}>
                        <video
                          controls
                          controlsList="nodownload"
                          disablePictureInPicture
                          loop
                          style={{
                            width: "100%",
                            maxHeight: 210,
                            objectFit: "contain",
                          }}
                        >
                          <source src={video.url} type="video/mp4" />
                        </video>
                      </Box>
                    )}
                    {video.status === API_STATUS.ERROR && (
                      <ColumnCenteredBox
                        sx={{
                          height: 210,
                          gap: 2,
                          backgroundColor: (theme) => alpha(theme.palette.background.custom1, 0.8),
                        }}
                      >
                        <ErrorIcon color="error" />
                        <Typography variant={"body2"} sx={{ whiteSpace: "pre-line" }}>
                          {t("juno.video.error.generate")}
                        </Typography>
                      </ColumnCenteredBox>
                    )}
                    {video.status === API_STATUS.PROCESSING && (
                      <Box sx={{ width: "100%", height: 210, position: "relative" }}>
                        <Skeleton variant="rectangular" width="100%" height={210} />
                        <ColumnCenteredBox
                          sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            height: 210,
                            width: "100%",
                            gap: 2,
                            backgroundColor: (theme) => alpha(theme.palette.background.custom1, 0.8),
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <CircularProgress />
                          <Typography variant="body2" sx={{ whiteSpace: "pre-line", textAlign: "center" }}>
                            {t("juno.video.processing")}
                          </Typography>
                        </ColumnCenteredBox>
                      </Box>
                    )}
                    <Box p={1}>
                      <RowBox justifyContent="space-between" mb={1}>
                        <RowBox>
                          <Typography
                            variant="body2"
                            sx={{
                              fontWeight: 600,
                              px: 1,
                              py: 0.2,
                              mr: 2,
                              backgroundColor: (theme) => theme.palette.secondary.dark,
                              borderRadius: "2px",
                              display: "inline-flex",
                            }}
                            color="#fff"
                            component="span"
                          >
                            {video.params.action == "i2v" ? (
                              <RowBox gap={0.5}>
                                <ImageIcon sx={{ fontSize: "16px" }} />
                                <span>I2V</span>
                              </RowBox>
                            ) : (
                              <RowBox gap={0.5}>
                                <FontDownloadIcon sx={{ fontSize: "16px" }} />
                                <span>T2V</span>
                              </RowBox>
                            )}
                          </Typography>
                          <Typography variant="body2" sx={{ fontWeight: 600 }} color="secondary">
                            {t(`juno.video.ai.${video.params.aiEngine.toLowerCase()}.title`)} -{" "}
                            {t(`juno.video.ai.${video.params.aiEngine.toLowerCase()}.model.${video.params.model}`)}
                          </Typography>
                        </RowBox>
                        <IconButton
                          onClick={(e) => handleMenuClick(e, video.uuid)}
                          size={"small"}
                          disabled={video.status === API_STATUS.PROCESSING}
                        >
                          <MoreVertIcon fontSize={"small"} />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl && selectedVideo === video.uuid)}
                          onClose={handleMenuClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          {video.status === API_STATUS.SUCCESS && (
                            <MenuItem onClick={() => handleVideoDownload(video.uuid)}>
                              <DownloadIcon fontSize={"small"} sx={{ mr: 2 }} color={"primary"} />
                              {t("juno.video.videoMenu.download")}
                            </MenuItem>
                          )}
                          {video.status !== API_STATUS.PROCESSING && (
                            <MenuItem onClick={() => handleDeleteVideo(video.uuid)}>
                              <DeleteIcon fontSize={"small"} sx={{ mr: 2 }} color={"error"} />
                              {t("juno.video.videoMenu.delete")}
                            </MenuItem>
                          )}
                        </Menu>
                      </RowBox>
                      <TextField
                        label={t("juno.video.params.input.label")}
                        value={video.params.prompt}
                        variant={"standard"}
                        fullWidth
                        rows={3}
                        multiline
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Box>
                  </Box>
                </Card>
              </Fade>
            </Grid>
          ))}
        {videos.length == 0 && (
          <Grid item xs={12}>
            <Typography variant={"subtitle2"}>No videos found</Typography>
          </Grid>
        )}
      </Grid>
      {/* Pagination */}
      {totalPages > 1 && (
        <Box mt={4} display="flex" justifyContent="center">
          <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} />
        </Box>
      )}
    </ColumnBox>
  );
};

export default LatestVideoList;
