import React from "react";
import { useTranslation } from "react-i18next";
import { useParamsContext } from "../../../../../context/juno/ParamsContext";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Slider } from "@mui/material";

const GuidanceScale = () => {
  const { t } = useTranslation();
  const { guidanceScale, setGuidanceScale } = useParamsContext();
  const params = new URLSearchParams(window.location.search);
  const aiEngine = params.get("ai_engine");

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mb: 1 }}>
          <Typography variant={"body2"}>{t("juno.input.guidanceScale")}</Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }} gap={2}>
          <Slider
            getAriaValueText={(value) => `${value}px`}
            value={guidanceScale[aiEngine!]}
            onChange={(e, value) => setGuidanceScale({ ...guidanceScale, [aiEngine!]: value as number })}
            step={0.5}
            min={1}
            max={20}
          />
          <Box
            sx={{
              width: "120px",
              bgcolor: "rgba(0, 0, 0, 0.1)",
              px: 1,
              py: 0.5,
              justifyContent: "center",
              display: "flex",
              borderRadius: "5px",
            }}
          >
            <Typography variant={"body2"}>{guidanceScale[aiEngine!]}</Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default GuidanceScale;
