import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import CasinoIcon from "@mui/icons-material/Casino";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import { Divider, Hidden, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useAlert } from "../../../../context/AlertContext";
import { AiEngineList, useVideo } from "../../../../context/juno/VideoContext";

import { useCheckCredit } from "../../../../hooks/useCreditCheck";
import { setCreditTrigger } from "../../../../redux/slices/triggerSlice";
import ProPlanModal from "../../../../components/common/ProPlanModal";
import { RowBox } from "../../../../utils/styledBox";
import { GradationButton } from "../../../../utils/gradationButton";
import Typography from "@mui/material/Typography";

type AiEngine = AiEngineList;

const InputPrompt = () => {
  const { t } = useTranslation();
  const { checkCredit } = useCheckCredit();
  const [openProDialog, setOpenProDialog] = useState(false);

  const theme = useTheme();
  const {
    prompt,
    setPrompt,
    processing,
    setProcessing,
    aiEngine,
    modelVersion,
    duration,
    aspectRatios,
    lumaLoop,
    menu,
    image1,
    image2,
    fetchVideoTrigger,
    setFetchVideoTrigger,
  } = useVideo();

  const [localPrompt, setLocalPrompt] = useState<string>("");
  const [rotationDegree, setRotationDegree] = useState(0);
  const { setAlert } = useAlert();
  const dispatch = useDispatch();
  const [updating, setUpdating] = useState(false);
  const [credit, setCredit] = useState<number | undefined>(undefined);
  const [disable3sec, setDisable3sec] = useState(false);

  useEffect(() => {
    if (disable3sec) {
      const timer = setTimeout(() => {
        setDisable3sec(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [disable3sec]);

  // 外部からのプロンプト変更をローカルに反映
  useEffect(() => {
    setLocalPrompt(prompt);
  }, [prompt]);

  const fetchCredit = async (aiEngine, model, duration) => {
    const url = "/api/v1/juno/video-credit";
    const csrftoken = Cookies.get("csrftoken");
    const headers = new Headers({
      "Content-Type": "application/json",
      "X-CSRFToken": csrftoken!,
    });
    const params = "aiEngine=" + aiEngine + "&model=" + model + "&duration=" + duration;
    const response = await fetch(url + "?" + params, {
      method: "GET",
      headers: headers,
    });

    return response.json();
  };

  useEffect(() => {
    fetchCredit(aiEngine, modelVersion[aiEngine], duration[aiEngine]).then((data) => {
      setCredit(data.credit);
    });
  }, [aiEngine, modelVersion, duration]);

  // プロンプト生成APIへのリクエスト
  const generateSampleText = async () => {
    try {
      setUpdating(true);
      if (!(await checkCredit())) return;
      setRotationDegree(rotationDegree + 360);
      setProcessing(true);

      const url = "/api/v1/juno/generate-prompt";
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ aiEngine: aiEngine }),
      });
      await response.json().then((data) => {
        setPrompt(data);
        dispatch(setCreditTrigger(true));
      });
    } catch (e) {
      console.error(e);
    } finally {
      setProcessing(false);
      setUpdating(false);
    }
  };

  // プロンプト生成APIへのリクエスト
  const updatePrompt = async () => {
    try {
      setUpdating(true);
      if (!(await checkCredit())) return;
      setProcessing(true);

      const url = "/api/v1/juno/update-prompt";
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          aiEngine: aiEngine,
          prompt: localPrompt,
        }),
      });
      await response.json().then((data) => {
        setPrompt(data);
        dispatch(setCreditTrigger(true));
      });
    } catch (e) {
      console.error(e);
    } finally {
      setProcessing(false);
      setUpdating(false);
    }
  };

  const Updating = () => {
    return (
      <div
        style={{
          width: 40,
          height: 40,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress size={20} color="primary" />
      </div>
    );
  };

  const request_api_url = "/api/v1/juno/generate-video";

  const getHeaders = () => {
    const csrftoken = Cookies.get("csrftoken");
    return new Headers({
      "Content-Type": "application/json",
      "X-CSRFToken": csrftoken!,
    });
  };

  // リクエストボディの生成
  const buildRequestBody = (overrides = {}) => ({
    generatedVideoUuid: "",
    prompt: localPrompt,
    aiEngine,
    action: menu,
    model: modelVersion[aiEngine as AiEngine],
    duration: duration[aiEngine as AiEngine],
    aspectRatio: aspectRatios[aiEngine as AiEngine],
    loop: lumaLoop,
    image1,
    image2,
    ...overrides,
  });

  const handleSubmit = async () => {
    try {
      setDisable3sec(true);
      if (!validation()) return;
      const res = await createRequest();

      if (res.status === "error" && res.message === "UPGRADE_PLAN") {
        setOpenProDialog(true);
        return;
      }

      if (res.status === "error" && res.message === "NO_CREDITS") {
        setOpenProDialog(true);
        return;
      }

      setFetchVideoTrigger(!fetchVideoTrigger);
      dispatch(setCreditTrigger(true));

      if (res.status === "success") {
        setAlert("success", t("juno.video.startGenerating"));
        await generateVideo(res.generated_video_uuid, res.usage_history_uuid);
      } else if (res.status === "error") {
        setAlert("error", t("juno.video.error.generate"));
      }
    } catch (e: any) {
      // エラーのステータスコードが524の場合は特別な処理
      if (e.response?.status === 524) {
        console.warn("Request Timeout 524");
      } else {
        console.error(e);
        setAlert("error", t("juno.video.error.generate"));
      }
    } finally {
      setFetchVideoTrigger(!fetchVideoTrigger);
      dispatch(setCreditTrigger(true));
      setDisable3sec(false);
      setProcessing(false);
    }
  };

  const validation = () => {
    if (localPrompt.trim().length === 0) {
      setDisable3sec(false);
      setAlert("error", t("juno.video.error.required.prompt"));
      return false;
    }
    // 512文字以上の場合
    if (localPrompt.length > 512) {
      setDisable3sec(false);
      setAlert("error", t("juno.video.error.max.prompt"));
      return false;
    }
    if (menu === "i2v") {
      if (aiEngine === "RUNWAY") {
        if (image1.trim().length === 0 && image2.trim().length === 0) {
          setDisable3sec(false);
          setAlert("error", t("juno.video.error.required.image"));
          return false;
        }
      } else {
        if (image1.trim().length === 0) {
          setDisable3sec(false);
          setAlert("error", t("juno.video.error.required.image"));
          return false;
        }
      }
    }

    // i2vの場合、画像が16MB以下かどうかチェック
    const maxSizeInBytes = 16000000; // 16MB
    const validateImageSize = (image: string) => {
      if (image.trim().length === 0) return false; // 空の場合は無視
      const base64Length = image.length - (image.indexOf(",") + 1); // ヘッダー部分を除外
      const padding = image.endsWith("==") ? 2 : image.endsWith("=") ? 1 : 0;
      const actualFileSizeInBytes = (base64Length * 3) / 4 - padding; // 実際のサイズをバイトに換算
      return actualFileSizeInBytes > maxSizeInBytes;
    };

    if (aiEngine === "RUNWAY") {
      if (image1.trim().length > 0 && validateImageSize(image1)) {
        setDisable3sec(false);
        setAlert("error", t("juno.input.uploadError.size", { size: "16" }));
        return false;
      }
      if (image2.trim().length > 0 && validateImageSize(image2)) {
        setDisable3sec(false);
        setAlert("error", t("juno.input.uploadError.size", { size: "16" }));
        return false;
      }
    } else {
      if (validateImageSize(image1)) {
        setDisable3sec(false);
        setAlert("error", t("juno.input.uploadError.size", { size: "16" }));
        return false;
      }
    }
    return true;
  };

  // リクエスト生成
  const createRequest = async () => {
    const response = await fetch(request_api_url, {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify(buildRequestBody()),
    });
    return response.json();
  };

  // 動画生成
  const generateVideo = async (generatedVideoUuid: string, usageHistoryUuid: string) => {
    setProcessing(true);
    setPrompt(localPrompt);

    const response = await fetch(request_api_url, {
      method: "PATCH",
      headers: getHeaders(),
      body: JSON.stringify(
        buildRequestBody({
          generatedVideoUuid,
          usageHistoryUuid,
        })
      ),
    });

    const res = await response.json();

    if (res === "success") {
      setFetchVideoTrigger(!fetchVideoTrigger);
      dispatch(setCreditTrigger(true));
    }
  };

  return (
    <>
      <RowBox sx={{ gap: 2, alignItems: "flex-end" }}>
        <TextField
          label={t("juno.video.params.input.label")}
          variant="outlined"
          autoComplete={"off"}
          placeholder={t("juno.video.params.input.placeholder")}
          autoFocus
          value={localPrompt}
          onChange={(event) => setLocalPrompt(event.target.value)}
          multiline
          fullWidth
          maxRows={4}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                  {localPrompt.trim().length === 0 ? (
                    updating ? (
                      <Updating />
                    ) : (
                      // サンプルプロンプト生成
                      <Tooltip title={t("juno.history.dice")}>
                        <span>
                          <IconButton onClick={generateSampleText} disabled={updating}>
                            <CasinoIcon
                              sx={{
                                transform: `rotate(${rotationDegree}deg)`,
                                transition: "transform 1s ease-in-out",
                              }}
                            />
                          </IconButton>
                        </span>
                      </Tooltip>
                    )
                  ) : // プロンプトアップデート
                  updating ? (
                    <Updating />
                  ) : (
                    <Tooltip title={t("juno.history.improve")}>
                      <span>
                        <IconButton onClick={() => updatePrompt()} disabled={updating}>
                          <AutoFixHighIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}
                  {/* delete */}
                  <Tooltip title={t("common.delete")}>
                    <span>
                      <IconButton
                        onClick={() => {
                          setLocalPrompt("");
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Divider orientation="vertical" sx={{ height: "28px", mx: 0.5 }} variant={"middle"} />
                  <Hidden smDown>
                    <Typography variant="body2" color="secondary" sx={{ ml: 2 }}>
                      @{aiEngine}:
                    </Typography>
                  </Hidden>
                </Box>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end" color="secondary" sx={{ mr: -2, height: "100%" }}>
                <GradationButton
                  onClick={() => handleSubmit()}
                  sx={{ minWidth: { xs: 40, sm: 240 }, height: 200, px: 2 }}
                  startIcon={disable3sec ? <CircularProgress size={20} /> : <SendIcon />}
                  disabled={disable3sec}
                >
                  <Hidden smDown>{t("juno.video.params.submit")}</Hidden>({credit})
                </GradationButton>
              </InputAdornment>
            ),
            sx: {
              borderRadius: 2,
              backgroundColor: theme.palette.background.paper,
              overflow: "hidden",
            },
            inputProps: { maxLength: 512 },
          }}
        />
      </RowBox>
      <RowBox sx={{ gap: 2, justifyContent: "space-between", mt: 0.5, px: 1 }}>
        <Typography variant="caption" color={localPrompt.length > 512 ? "error" : "textSecondary"}>
          {localPrompt.length}/512
        </Typography>
        <Typography variant="caption">{t("juno.video.params.input.warning")}</Typography>
      </RowBox>
      <ProPlanModal open={openProDialog} setOpen={setOpenProDialog} />
    </>
  );
};
export default InputPrompt;
