import Box from "@mui/material/Box";
import * as React from "react";
import { useArticleContext } from "../../../../context/ArticleContext";
import { useTranslation } from "react-i18next";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import file_sidebar from "../../../../json/file_sidebar.json";
import { StyledTypography } from "../../../../utils/styledTypography";

export default function Tone() {
  const { t } = useTranslation();
  const toneList = file_sidebar.toneList;
  const { tone, setTone } = useArticleContext();

  return (
    <>
      {/* トーン */}
      <Box>
        <Box mb={2}>
          <StyledTypography variant="body1" gutterBottom>
            {t("article.tone")}
          </StyledTypography>
        </Box>
        <Box>
          <FormControl variant="outlined" fullWidth>
            {/*<InputLabel>{t('article.tone')}</InputLabel>*/}
            <Select
              variant={"standard"}
              value={tone}
              // label={t('article.tone')}
              onChange={(e) => setTone(e.target.value)}
              sx={{ maxWidth: 280 }}
            >
              <MenuItem value="">&nbsp;</MenuItem>
              {toneList.map((item, index) => (
                <MenuItem value={t(`textEditor.sidebar.toneType.${item}`)} key={index}>
                  {t(`textEditor.sidebar.toneType.${item}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
    </>
  );
}
