import React from "react";
import { useTranslation } from "react-i18next";
import { useParamsContext } from "../../../../../context/juno/ParamsContext";
import Box from "@mui/material/Box";
import { Button, IconButton, Popover, Typography } from "@mui/material";
import { alpha } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { ChromePicker } from "react-color";

const Colors = () => {
  const { t } = useTranslation();
  const { colorsRecraft, setColorsRecraft } = useParamsContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [currentColor, setCurrentColor] = React.useState({ r: 255, g: 255, b: 255 });

  return (
    <Box sx={{ mb: 2 }}>
      <Typography variant={"body2"}>{t("juno.input.colorSelection.title")}</Typography>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", flexWrap: "wrap", mt: 1 }} gap={1}>
        {colorsRecraft.map((color, index) => (
          <Box
            key={index}
            sx={{
              width: 40,
              height: 40,
              backgroundColor: `rgb(${color.r}, ${color.g}, ${color.b})`,
              borderRadius: 1,
              position: "relative",
            }}
          >
            <IconButton
              sx={{
                position: "absolute",
                top: -10,
                right: -10,
                backgroundColor: alpha("#000", 0.5),
                width: 20,
                height: 20,
                color: "white",
                "&:hover": {
                  backgroundColor: alpha("#000", 0.8),
                },
              }}
              onClick={() => {
                const newColors = [...colorsRecraft];
                newColors.splice(index, 1);
                setColorsRecraft(newColors);
              }}
            >
              <CloseIcon sx={{ fontSize: 16 }} />
            </IconButton>
          </Box>
        ))}
        <IconButton
          size="large"
          onClick={(event) => setAnchorEl(event.currentTarget)}
          sx={{
            width: 40,
            height: 40,
            borderRadius: 1,
            border: "1px dashed grey",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <AddIcon />
        </IconButton>
      </Box>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box p={2}>
          <ChromePicker
            color={currentColor}
            onChange={(color) => {
              setCurrentColor(color.rgb);
            }}
            disableAlpha
          />
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button onClick={() => setAnchorEl(null)}>{t("common.cancel")}</Button>
            <Button
              onClick={() => {
                setColorsRecraft([...colorsRecraft, currentColor]);
                setAnchorEl(null);
              }}
            >
              {t("common.add")}
            </Button>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};
export default Colors;
