import { Button, IconButton, Slider, Stack, Tooltip, Typography } from "@mui/material";
import AspectRatio from "../params/AspectRatio";
import { FaArrowPointer, FaEraser, FaExpand, FaImage, FaPaintbrush, FaTrash } from "react-icons/fa6";
import { ColumnCenteredBox } from "../../../../../utils/styledBox";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParamsContext } from "../../../../../context/juno/ParamsContext";
import { ImageObject, LineData } from "../../../../../types/junoTypes";
import GeneratedImageModal from "../../../../../components/common/GeneratedImageModal";
import PhotoFilterIcon from "@mui/icons-material/PhotoFilter";
import UnsplashModal from "../../../../../components/common/UnsplashModal";
import { FaUnsplash } from "react-icons/fa6";
interface ToolbarProps {
  setImages: React.Dispatch<React.SetStateAction<ImageObject[]>>;
  setSelectedImageId: React.Dispatch<React.SetStateAction<string | null>>;
  setBrushSize: React.Dispatch<React.SetStateAction<number>>;
  brushSize: number;
  selectedTool: string;
  setSelectedTool: React.Dispatch<React.SetStateAction<ToolType>>;
  setStageWidth: React.Dispatch<React.SetStateAction<number>>;
  setStageHeight: React.Dispatch<React.SetStateAction<number>>;
  setLines: React.Dispatch<React.SetStateAction<LineData[]>>;
}

type ToolType = "select" | "brush" | "eraser";

const Toolbar = ({
  setImages,
  setSelectedImageId,
  setBrushSize,
  brushSize,
  selectedTool,
  setSelectedTool,
  setStageWidth,
  setStageHeight,
  setLines,
}: ToolbarProps) => {
  const { t } = useTranslation();
  const { aspectRatio } = useParamsContext();
  const params = new URLSearchParams(window.location.search);
  const aiEngine = params.get("ai_engine");

  const changeAspectRatio = () => {
    const long = 1024;
    switch (aspectRatio[aiEngine!]) {
      case "1:1":
        setStageWidth(long);
        setStageHeight(long);
        break;
      case "16:9":
        setStageWidth(long);
        setStageHeight(long * (9 / 16));
        break;
      case "4:3":
        setStageWidth(long);
        setStageHeight(long * (3 / 4));
        break;
      case "9:16":
        setStageWidth(long * (9 / 16));
        setStageHeight(long);
        break;
      case "3:4":
        setStageWidth(long * (3 / 4));
        setStageHeight(long);
        break;
      default:
    }
  };

  useEffect(() => {
    changeAspectRatio();
  }, [aspectRatio]);

  // 画像のアップロード
  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const img = new Image();
      const objectUrl = URL.createObjectURL(file);
      img.src = objectUrl;
      img.onload = () => {
        // 長辺が800になるようにScaleを調整
        const scale = 800 / Math.max(img.width, img.height);
        const newImage: ImageObject = {
          image: img,
          x: 0,
          y: 0,
          scaleX: scale,
          scaleY: scale,
          id: Math.random().toString(36).substring(7),
          width: img.width,
          height: img.height,
        };

        setImages((prev) => [...prev, newImage]);

        // アップロードした画像を選択
        setSelectedImageId(newImage.id);

        // 選択ツールを有効にする
        setSelectedTool("select");
      };
    }
  };

  const addImageFromURL = (image_url: string) => {
    const img = new Image();
    img.crossOrigin = "anonymous"; // CORS の許可を設定
    img.src = image_url + "public";

    img.onload = () => {
      const scale = 800 / Math.max(img.width, img.height);
      const newImage: ImageObject = {
        image: img,
        x: 0,
        y: 0,
        scaleX: scale,
        scaleY: scale,
        id: Math.random().toString(36).substring(7),
        width: img.width,
        height: img.height,
      };

      setImages((prev) => [...prev, newImage]);

      // アップロードした画像を選択
      setSelectedImageId(newImage.id);

      // 選択ツールを有効にする
      setSelectedTool("select");
    };
  };

  const [openGeneratedImageModal, setOpenGeneratedImageModal] = React.useState(false);
  const [unsplashModalOpen, setUnsplashModalOpen] = React.useState(false);

  // リセット
  const handleReset = () => {
    setImages([]);
    setSelectedImageId(null);
    setSelectedTool("brush");
    setLines([]);
  };

  const selectTool = (tool: ToolType) => {
    setSelectedTool(tool);
    setSelectedImageId(null);
  };

  return (
    <Stack
      direction="column"
      spacing={2}
      alignItems="center"
      sx={{ position: "absolute", top: "50%", left: "5%", transform: "translate(-50%, -50%)", zIndex: 100 }}
    >
      {/* アスペクト比 */}
      <Tooltip
        title={
          <ColumnCenteredBox p={1}>
            <Typography variant={"caption"}>{t("juno.input.imageEditor.aspectRatio")}</Typography>
            <AspectRatio />
          </ColumnCenteredBox>
        }
        arrow
        disableFocusListener
        placement="right"
      >
        <IconButton component="label" size="large">
          <FaExpand style={{ fontSize: 16 }} />
        </IconButton>
      </Tooltip>

      {/* 画像のアップロード */}
      <Tooltip title={t("juno.input.imageEditor.upload")} arrow placement="right">
        <IconButton component="label" size="large">
          <input type="file" hidden accept="image/*" onChange={handleImageUpload} />
          <FaImage style={{ fontSize: 16 }} />
        </IconButton>
      </Tooltip>

      {/* 生成画像の選択 */}
      <Tooltip title={t("juno.input.imageEditor.generated")} arrow placement="right">
        <IconButton onClick={() => setOpenGeneratedImageModal(true)} size="large">
          <PhotoFilterIcon sx={{ fontSize: 20 }} />
        </IconButton>
      </Tooltip>

      <GeneratedImageModal
        open={openGeneratedImageModal}
        setOpen={setOpenGeneratedImageModal}
        setImage={addImageFromURL}
      />

      {/* Unsplash */}
      <Tooltip title={t("juno.input.imageEditor.unsplash")} arrow placement="right">
        <IconButton onClick={() => setUnsplashModalOpen(true)} size="large">
          <FaUnsplash style={{ fontSize: 16 }} />
        </IconButton>
      </Tooltip>
      <UnsplashModal open={unsplashModalOpen} setOpen={setUnsplashModalOpen} setImage={addImageFromURL} />

      {/* 画像選択ツール */}
      <Tooltip
        title={
          <Typography variant={"caption"} sx={{ whiteSpace: "pre-line" }}>
            {t("juno.input.imageEditor.select")}
          </Typography>
        }
        arrow
        placement="right"
      >
        <IconButton
          onClick={() => selectTool("select")}
          color={selectedTool === "select" ? "primary" : "default"}
          size="large"
        >
          <FaArrowPointer style={{ fontSize: 16 }} />
        </IconButton>
      </Tooltip>

      {/* ブラシサイズ */}
      <Tooltip
        placement="right"
        arrow
        title={
          <ColumnCenteredBox sx={{ p: 1 }}>
            <Typography variant={"caption"}>{t("juno.input.imageEditor.brushSize")}</Typography>
            <Slider
              aria-label="brush size"
              value={brushSize}
              onChange={(_, value) => {
                setBrushSize(value as number);
                setSelectedTool("brush");
              }}
              min={2}
              max={100}
              sx={{ width: 100 }}
            />
          </ColumnCenteredBox>
        }
      >
        <IconButton
          onClick={() => selectTool("brush")}
          color={selectedTool === "brush" ? "primary" : "default"}
          size="large"
        >
          <FaPaintbrush style={{ fontSize: 16 }} />
        </IconButton>
      </Tooltip>

      {/* 消しゴム */}
      <Tooltip
        title={
          <ColumnCenteredBox sx={{ p: 1 }}>
            <Typography variant={"caption"}>{t("juno.input.imageEditor.eraserSize")}</Typography>
            <Slider
              aria-label="brush size"
              value={brushSize}
              onChange={(_, value) => {
                setBrushSize(value as number);
                setSelectedTool("brush");
              }}
              min={2}
              max={100}
              sx={{ width: 100 }}
            />
          </ColumnCenteredBox>
        }
        arrow
        placement="right"
      >
        <IconButton
          onClick={() => selectTool("eraser")}
          color={selectedTool === "eraser" ? "primary" : "default"}
          size="large"
        >
          <FaEraser style={{ fontSize: 16 }} />
        </IconButton>
      </Tooltip>

      {/* リセット */}
      <Tooltip title={t("juno.input.imageEditor.reset")} arrow placement="right">
        <IconButton onClick={() => handleReset()} color={"error"} size="large">
          <FaTrash style={{ fontSize: 16 }} />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};
export default Toolbar;
