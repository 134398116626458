import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import ImageUpload from "../ImageUpload";

const Image = () => {
  const { t } = useTranslation();
  const params = new URLSearchParams(window.location.search);
  const aiEngine = params.get("ai_engine");

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} gap={1} mb={1}>
        <Typography variant={"body2"}>
          {aiEngine === "FluxPuLID" ? t("juno.input.imageFace") : t("juno.input.image")}
        </Typography>
      </Box>
      <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
        <ImageUpload />
      </Box>
    </Box>
  );
};
export default Image;
