import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CropDinIcon from "@mui/icons-material/CropDin";
import Crop169Icon from "@mui/icons-material/Crop169";
import { useTranslation } from "react-i18next";
import { useParamsContext } from "../../../../../context/juno/ParamsContext";
import Crop32Icon from "@mui/icons-material/Crop32";
import { AspectRatio as AspectRatioType } from "../../../../../types/junoTypes";
import Crop75Icon from "@mui/icons-material/Crop75";

const AspectRatio = () => {
  const { t } = useTranslation();
  const { setWidthPartial, setHeightPartial, width, height, aspectRatio, setAspectRatio } = useParamsContext();
  const params = new URLSearchParams(window.location.search);
  const aiEngine = params.get("ai_engine");

  const aspectRatiosFlux = [
    { ratio: "1:1", icon: CropDinIcon },
    { ratio: "16:9", icon: Crop169Icon },
    { ratio: "4:3", icon: Crop32Icon },
    { ratio: "9:16", icon: Crop169Icon, rotate: "90deg" },
    { ratio: "3:4", icon: Crop32Icon, rotate: "90deg" },
  ];

  const aspectRatiosIdeogram = [
    { ratio: "1:1", icon: CropDinIcon },
    { ratio: "16:9", icon: Crop169Icon },
    { ratio: "3:2", icon: Crop32Icon },
    { ratio: "4:3", icon: Crop75Icon },
    { ratio: "9:16", icon: Crop169Icon, rotate: "90deg" },
    { ratio: "2:3", icon: Crop32Icon, rotate: "90deg" },
    { ratio: "3:4", icon: Crop75Icon, rotate: "90deg" },
  ];

  const aspectRatiosSD3 = [
    { ratio: "1:1", icon: CropDinIcon },
    { ratio: "16:9", icon: Crop169Icon },
    { ratio: "3:2", icon: Crop32Icon },
    { ratio: "5:4", icon: Crop75Icon },
    { ratio: "9:16", icon: Crop169Icon, rotate: "90deg" },
    { ratio: "2:3", icon: Crop32Icon, rotate: "90deg" },
    { ratio: "4:5", icon: Crop75Icon, rotate: "90deg" },
  ];

  const renderAspectRatioIcons = () => {
    let aspectRatios = aspectRatiosFlux;
    if (["Ideogram2", "Ideogram2Turbo"].includes(aiEngine!)) {
      aspectRatios = aspectRatiosIdeogram;
    }
    if (["SD35", "SDUltra"].includes(aiEngine!)) {
      aspectRatios = aspectRatiosSD3;
    }

    const clickButton = (e, ratio: string) => {
      e.stopPropagation();
      e.preventDefault();
      setAspectRatio({ ...aspectRatio, [aiEngine!]: ratio as AspectRatioType });
    };

    return aspectRatios.map(({ ratio, icon: Icon, rotate }) => (
      <Box key={ratio} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <IconButton
          onClick={(e) => clickButton(e, ratio)}
          color={aspectRatio[aiEngine!] === ratio ? "primary" : "default"}
        >
          <Icon sx={{ rotate: rotate || "0deg" }} />
        </IconButton>
        <Typography variant={"caption"} onClick={(e) => clickButton(e, ratio)} sx={{ cursor: "pointer" }}>
          {ratio}
        </Typography>
      </Box>
    ));
  };

  const handleAspectRatioChange = (aspect: number) => {
    if (aspect === 74) {
      setWidthPartial("DallE3", 1792);
      setHeightPartial("DallE3", 1024);
    }
    if (aspect === 47) {
      setWidthPartial("DallE3", 1024);
      setHeightPartial("DallE3", 1792);
    }
    if (aspect === 11) {
      setWidthPartial("DallE3", 1024);
      setHeightPartial("DallE3", 1024);
    }
  };

  const selectedAspectRatio = () => {
    if (width.DallE3 === 1024 && height.DallE3 === 1024) {
      return 11;
    }
    if (width.DallE3 === 1792 && height.DallE3 === 1024) {
      return 74;
    }
    if (width.DallE3 === 1024 && height.DallE3 === 1792) {
      return 47;
    }
  };

  return (
    <Box>
      {!["Flux1ProFill", "Flux1DevFill"].includes(aiEngine!) && (
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} gap={1} mb={1}>
          <Typography variant={"body2"}>{t("juno.input.aspectRatio.title")}</Typography>
        </Box>
      )}
      {aiEngine == "DallE3" ? (
        <>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", pb: 2 }} gap={1}>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <IconButton
                onClick={() => handleAspectRatioChange(11)}
                color={selectedAspectRatio() === 11 ? "primary" : "default"}
              >
                <CropDinIcon />
              </IconButton>
              <Typography variant={"caption"} onClick={() => handleAspectRatioChange(11)} sx={{ cursor: "pointer" }}>
                1 : 1
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <IconButton
                onClick={() => handleAspectRatioChange(74)}
                color={selectedAspectRatio() === 74 ? "primary" : "default"}
              >
                <Crop169Icon />
              </IconButton>
              <Typography variant={"caption"} onClick={() => handleAspectRatioChange(74)} sx={{ cursor: "pointer" }}>
                7 : 4
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <IconButton
                onClick={() => handleAspectRatioChange(47)}
                color={selectedAspectRatio() === 47 ? "primary" : "default"}
              >
                <Crop169Icon sx={{ rotate: "90deg" }} />
              </IconButton>
              <Typography variant={"caption"} onClick={() => handleAspectRatioChange(47)} sx={{ cursor: "pointer" }}>
                4 : 7
              </Typography>
            </Box>
          </Box>
        </>
      ) : (
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mb: 2 }} gap={1}>
          {renderAspectRatioIcons()}
        </Box>
      )}
    </Box>
  );
};
export default AspectRatio;
