import BoltIcon from "@mui/icons-material/Bolt";
import LaunchIcon from "@mui/icons-material/Launch";
import { Button, darken, Fade, Hidden, Paper } from "@mui/material";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { Box, useTheme } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useLoginModalContext } from "../../context/LoginModalContext";
import { useLoginStatus } from "../../context/LoginStatusContext";
import { useCustomNavigate } from "../../hooks/useCustomNavigate";
import { ColumnCenteredBox, RowCenteredBox } from "../../utils/styledBox";
import { customLink } from "../../utils/utils";

const DisplayPath = [
  undefined,
  "",
  "notes-nebula",
  "pricing",
  "preferences",
  "privacy-policy",
  "terms-of-service",
  "legal-notice",
];

const Footer = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const path = useLocation().pathname.split("/")[2];
  const { isLoggedIn } = useLoginStatus();
  const loginModalContext = useLoginModalContext();
  const navigate = useCustomNavigate();

  const handleSupportClick = () => {
    if (isLoggedIn) {
      navigate("/preferences/misc?supportForm=open");
    } else {
      loginModalContext.setActiveModal("login");
    }
  };

  // ログインしていない場合のみ "juno" を DisplayPath に追加
  const currentDisplayPath = isLoggedIn ? DisplayPath : [...DisplayPath, "juno"];

  if (!currentDisplayPath.includes(path)) {
    return null;
  }

  return (
    <Fade in={true} timeout={1500}>
      <Box
        sx={{
          mt: { xs: 2, md: 4 },
          display: "flex",
          justifyContent: "center",
          bottom: 0,
          borderRadius: 2,
          m: 2,
          backgroundColor: theme.palette.background.custom2,
          flexDirection: "column",
          overflow: "hidden",
          pt: 2,
          transition: "all 0.3s",
        }}
      >
        <Container maxWidth="lg" sx={{ py: 1 }}>
          <ColumnCenteredBox sx={{ height: "100%", width: "100%" }}>
            <ColumnCenteredBox my={4}>
              <img
                src={
                  theme.palette.mode === "dark"
                    ? "/images/logo/Diocode-2-white-high-res.png"
                    : "/images/logo/Diocode-2-black-high-res.png"
                }
                alt="logo"
                style={{
                  width: 200,
                  objectFit: "cover",
                }}
              />
            </ColumnCenteredBox>
            <RowCenteredBox mb={4}>
              <BoltIcon color="secondary" fontSize={"small"} />
              <Typography variant="caption" ml={0.5} color={theme.palette.text.disabled} fontWeight={"bold"}>
                Tenak.ai is powered by Diocode, Inc.
              </Typography>
            </RowCenteredBox>
            <Hidden mdDown>
              <RowCenteredBox mb={2}>
                <Menu handleSupportClick={handleSupportClick} />
              </RowCenteredBox>
            </Hidden>
            <Hidden mdUp>
              <ColumnCenteredBox mb={2}>
                <Menu handleSupportClick={handleSupportClick} />
              </ColumnCenteredBox>
            </Hidden>
          </ColumnCenteredBox>
        </Container>
        <Paper
          elevation={0}
          sx={{
            width: "100%",
            backgroundColor: darken(theme.palette.background.custom2, 0.05),
            borderRadius: 0,
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Typography variant="caption" color={theme.palette.grey[700]} py={1}>
            © 2024 {t("title")} - {t("company")} All rights reserved.
          </Typography>
        </Paper>
      </Box>
    </Fade>
  );
};
export default Footer;

const Menu = ({ handleSupportClick }: { handleSupportClick: () => void }) => {
  const { t } = useTranslation();
  return (
    <>
      <Button variant="text" component={Link} href={customLink("")}>
        {t("title2")}
      </Button>
      <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
      <Button variant="text" component={Link} href={customLink("/notes-nebula")}>
        {t("common.footer.notesNebula")}
      </Button>
      <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
      <Button variant="text" component={Link} href={customLink("/juno")}>
        {t("common.footer.juno")}
      </Button>
      <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
      <Button variant="text" component={Link} href={customLink("/pricing")}>
        {t("common.footer.pricing")}
      </Button>
      <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
      <Button variant="text" component={Link} href={customLink("/terms-of-service")}>
        {t("common.footer.terms")}
      </Button>
      <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
      <Button variant="text" component={Link} href={customLink("/privacy-policy")}>
        {t("common.footer.privacy")}
      </Button>
      <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
      <Button variant="text" sx={{ cursor: "pointer" }} onClick={handleSupportClick}>
        {t("common.footer.contact")}
      </Button>
      <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
      <Button variant="text" component={Link} href={"https://diocode.jp/"} target="_blank" endIcon={<LaunchIcon />}>
        {t("company")}
      </Button>
    </>
  );
};
