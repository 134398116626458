import FontDownloadIcon from "@mui/icons-material/FontDownload";
import ImageIcon from "@mui/icons-material/Image";
import PhotoSizeSelectLargeIcon from "@mui/icons-material/PhotoSizeSelectLarge";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  Grid,
  Paper,
  Popover,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useCustomNavigate } from "../../../../hooks/useCustomNavigate";
import BrushIcon from "@mui/icons-material/Brush";

export default function SelectMenu() {
  const { t } = useTranslation();
  const params = new URLSearchParams(window.location.search);
  const menu = params.get("menu");
  const ai_engine = params.get("ai_engine");
  const navigate = useCustomNavigate();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleMenuChange = (menu: string) => {
    let url = `/juno/image-generator?menu=${menu}`;
    if (ai_engine) url += `&ai_engine=${ai_engine}`;
    navigate(url);
    setAnchorEl(null);
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const getMenuIcon = () => {
    switch (menu) {
      case "txt2img":
        return <FontDownloadIcon color="primary" />;
      case "img2img":
        return <ImageIcon color="primary" />;
      case "upscale":
        return <PhotoSizeSelectLargeIcon color="primary" />;
      case "editor":
        return <BrushIcon color="primary" />;
      default:
        return null;
    }
  };

  const getMenuTitle = () => {
    switch (menu) {
      case "txt2img":
        return t("juno.menu.txt2img");
      case "img2img":
        return t("juno.menu.img2img");
      case "upscale":
        return t("juno.menu.upscale");
      case "editor":
        return t("juno.menu.editor");
      default:
        return null;
    }
  };

  const renderMenuCard = (menuType: string, icon: React.ReactNode, label: string, img: string) => (
    <Grid item xs={12} sm={4}>
      <Card
        sx={{
          border: menu === menuType ? "2px solid" : "0px solid",
          borderColor: menu === menuType ? "primary.main" : "grey.300",
          height: "100%",
        }}
        elevation={0}
      >
        <CardActionArea onClick={() => handleMenuChange(menuType)}>
          <Box position="relative">
            <CardMedia
              component="img"
              image={`/images/juno/ai-engine/${img}.png`}
              alt={label}
              style={{ objectFit: "cover" }}
            />
            <Box
              position="absolute"
              bottom={0}
              left={0}
              bgcolor="rgba(0, 0, 0, 0.5)"
              color="white"
              width="100%"
              pl={1}
              py={0.5}
              display="flex"
              alignItems="center"
            >
              {icon}
              <Typography variant="button" whiteSpace="nowrap">
                {label}
              </Typography>
            </Box>
          </Box>
        </CardActionArea>
      </Card>
    </Grid>
  );

  return (
    <Paper elevation={theme.palette.mode === "dark" ? 1 : 0}>
      <Box
        sx={{
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Box sx={{ justifyContent: "center", display: "flex", flexDirection: "column", width: "100%" }}>
          <Button onClick={handlePopoverOpen} variant="text" startIcon={getMenuIcon()} sx={{ width: "100%" }}>
            {getMenuTitle()}
          </Button>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            elevation={0}
          >
            <Box sx={{ p: 2, backgroundColor: theme.palette.background.custom1 }}>
              <Grid container spacing={2} sx={{ maxWidth: 700 }}>
                {renderMenuCard(
                  "txt2img",
                  <FontDownloadIcon fontSize="small" sx={{ mr: 1 }} />,
                  "Text to Image",
                  "t2i"
                )}
                {renderMenuCard("img2img", <ImageIcon fontSize="small" sx={{ mr: 1 }} />, "Image to Image", "i2i")}
                {renderMenuCard(
                  "upscale",
                  <PhotoSizeSelectLargeIcon fontSize="small" sx={{ mr: 1 }} />,
                  "Image Upscaling",
                  "upscale"
                )}
                {renderMenuCard("editor", <BrushIcon fontSize="small" sx={{ mr: 1 }} />, "Image Editor", "editor")}
              </Grid>
            </Box>
          </Popover>
        </Box>
      </Box>
    </Paper>
  );
}
