export const aiEngineColor = (ai_engine) => {
  switch (ai_engine) {
    case "DallE3":
      return "#3e8674";
    case "FluxPuLID":
      return "#2d6565";
    case "SDXL":
      return "#5365ad";
    case "SD3":
    case "SD35":
      return "#377079";
    case "SDUltra":
      return "#244a50";
    case "AuraSR":
      return "#3e6e67";
    case "FLUX":
    case "Flux1Pro":
    case "Flux11Pro":
    case "Flux11ProUltra":
      return "#3b3b4f";
    case "Flux1Dev":
      return "#443b4f";
    case "Flux1Schnell":
      return "#4f3b4f";
    case "Flux1RealismLoRA":
      return "#3b424f";
    case "FluxJuno":
      return "#a44040";
    case "Flux11ProUltraRedux":
    case "Flux11ProRedux":
    case "Flux1DevRedux":
    case "Flux1SchnellRedux":
      return "#3b4f4f";
    case "Flux1ProCanny":
    case "Flux1DevCannyLoRA":
      return "#3b4f43";
    case "Flux1ProDepth":
    case "Flux1DevDepthLoRA":
      return "#4d4f3b";
    case "Flux1ProFill":
      return "#4f3b3b";
    case "Flux1DevFill":
      return "#4d4141";
    case "RecraftV3":
      return "#9f7b3d";
    case "Ideogram2":
      return "#426c3d";
    case "Ideogram2Turbo":
      return "#68964c";
    case "Ideogram2Edit":
      return "#335e18";
    case "Ideogram2TurboEdit":
      return "#3d5e2a";
    default:
      return "#181818";
  }
};

//export type AiEngineList =
//   | "DallE3"
//   | "SD35"
//   | "SDUltra"
//   | "Flux11Pro"
//   | "Flux11ProUltra"
//   | "Flux1Dev"
//   | "Flux1Schnell"
//   | "Flux1RealismLoRA"
//   | "FluxJuno"
//   | "FluxPuLID"
//   | "Flux11ProUltraRedux"
//   | "Flux11ProRedux"
//   | "Flux1ProCanny"
//   | "Flux1ProDepth"
//   | "Flux1DevRedux"
//   | "Flux1DevCannyLoRA"
//   | "Flux1DevDepthLoRA"
//   | "Flux1SchnellRedux"
//   | "Flux1ProFill"
//   | "RecraftV3"
//   | "Ideogram2"
//   | "Ideogram2Turbo"
//   | "AuraSR"
//   | "ClarityUpscaler"
//   | "CreativeUpscaler";
