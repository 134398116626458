import { Box, Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import ButtonBase from "@mui/material/ButtonBase";
import Grow from "@mui/material/Grow";
import AddIcon from "@mui/icons-material/Add";
import { useAlert } from "../../../context/AlertContext";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useBrowsingMethods } from "../../../hooks/useBrowsingMethods";

type WorkspaceType = {
  name: string;
  path: string;
  last_history: string;
};

const BrowsingListCards = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useCustomNavigate();
  const [workspaces, setWorkspaces] = useState<WorkspaceType[]>([]);
  const { setAlert } = useAlert();
  const updatedWorkspace = useSelector((state: RootState) => state.browsing.triggerFetch);
  const { addNewWorkspace } = useBrowsingMethods();

  const newWorkspace = async () => {
    try {
      const res = await addNewWorkspace();
      if (!res) return;
      workspaces.push(res.data);
      get_workspaces().then(() => {
        setAlert("success", t("browsing.createWorkspace"));
        setWorkspaces(workspaces);
        navigate(res.data.path);
      });
    } catch (error) {
      console.error("An unknown error occurred:", error);
    }
  };

  useEffect(() => {
    get_workspaces();
  }, [updatedWorkspace]);

  const get_workspaces = async () => {
    try {
      const res = await axios.get("/api/v1/workspace/");
      setWorkspaces(res.data.data);
    } catch (error) {
      console.error("An unknown error occurred:", error);
    }
  };

  return (
    <Box>
      <Grid container spacing={2}>
        {workspaces.map((ws: any, index: number) => {
          return (
            <Grid item xs={6} sm={2.4} key={index}>
              <ButtonBase
                onClick={() => navigate(ws.path)}
                sx={{
                  transition: "0.3s",
                  width: "100%",
                  "&:hover": {
                    transform: "translateY(-5px)",
                  },
                }}
              >
                <Grow in={true} timeout={(index + 1) * 500}>
                  <Paper
                    elevation={theme.palette.mode === "dark" ? 1 : 0}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      height: 170,
                      overflow: "hidden",
                    }}
                  >
                    <Box
                      sx={{
                        height: "5px",
                        width: "100%",
                        background: theme.custom.gradient.main,
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        height: 160,
                        px: 2,
                        py: 1,
                        mt: 1,
                      }}
                    >
                      <Typography
                        variant={"subtitle2"}
                        component={"p"}
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                          mb: 1,
                          textAlign: "left",
                        }}
                      >
                        {ws.name}
                      </Typography>
                      <Typography
                        variant={"caption"}
                        component={"p"}
                        sx={{
                          mb: 1,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 5,
                          WebkitBoxOrient: "vertical",
                          textAlign: "left",
                        }}
                      >
                        {ws.last_history}
                      </Typography>
                    </Box>
                  </Paper>
                </Grow>
              </ButtonBase>
            </Grid>
          );
        })}
        {workspaces.length < 5 && (
          <Grid item xs={6} sm={2.4}>
            <ButtonBase
              onClick={newWorkspace}
              sx={{
                width: "100%",
                transition: "0.3s",
                "&:hover": {
                  transform: "translateY(-5px)",
                },
              }}
            >
              <Grow in={true} timeout={500 * (workspaces.length + 1)}>
                <Paper
                  elevation={theme.palette.mode === "dark" ? 1 : 0}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: 170,
                    width: "100%",
                    border: "2px dashed" + theme.palette.primary.main,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      m: 1,
                    }}
                  >
                    <Typography variant={"subtitle1"} textAlign={"center"} component={"p"} sx={{ mb: 1 }}>
                      Add a new Workspace
                    </Typography>
                    <AddIcon sx={{ mb: 1, color: theme.palette.primary.main }} />
                    <Typography variant={"caption"} component={"p"}>
                      {t("dashboard.addWorkspace")}
                    </Typography>
                  </Box>
                </Paper>
              </Grow>
            </ButtonBase>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
export default BrowsingListCards;
