import { useTranslation } from "react-i18next";
import { AspectRatioIconType, useVideo } from "../../../../context/juno/VideoContext";
import CropDinIcon from "@mui/icons-material/CropDin";
import Crop169Icon from "@mui/icons-material/Crop169";
import Typography from "@mui/material/Typography";
import { ColumnBox } from "../../../../utils/styledBox";
import Slider from "@mui/material/Slider";
import React from "react";
import AspectRatioIcons from "./AspectRatioIcons";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ImageUpload from "./ImageUpload";

const ParamsKling = () => {
  const { t } = useTranslation();
  const { menu, updateParams, image1, setImage1, duration, modelVersion } = useVideo();

  const aspectRatio: AspectRatioIconType[] = [
    { ratio: "1:1", icon: CropDinIcon },
    { ratio: "16:9", icon: Crop169Icon },
    { ratio: "9:16", icon: Crop169Icon, rotate: "90deg" },
  ];

  const durationMarks = [
    {
      value: 5,
      label: "5s",
    },
    {
      value: 10,
      label: "10s",
    },
  ];

  return (
    <ColumnBox sx={{ gap: 4 }}>
      {menu === "i2v" && (
        <ColumnBox sx={{ justifyContent: "center" }}>
          <Typography variant="body2" mb={1}>
            {t("juno.video.params.upload")}
          </Typography>
          <ImageUpload image={image1} setImage={setImage1} />
        </ColumnBox>
      )}
      <ColumnBox>
        {/* Model */}
        <Typography variant="body2" mb={1}>
          {t("juno.video.ai.model")}
        </Typography>
        <Select
          value={modelVersion.KLING}
          onChange={(e) => updateParams("KLING", "modelVersion", e.target.value)}
          variant="standard"
          fullWidth
        >
          <MenuItem value={"V1_5_PRO"}>{t("juno.video.ai.kling.model.V1_5_PRO")}</MenuItem>
          <MenuItem value={"V1_0_PRO"}>{t("juno.video.ai.kling.model.V1_0_PRO")}</MenuItem>
          <MenuItem value={"V1_0_STANDARD"}>{t("juno.video.ai.kling.model.V1_0_STANDARD")}</MenuItem>
        </Select>
      </ColumnBox>
      <ColumnBox>
        {/* Duration */}
        <Typography variant="body2" mb={1}>
          {t("juno.video.params.duration")}
        </Typography>
        <Slider
          aria-label="duration"
          marks={durationMarks}
          min={5}
          max={10}
          step={5}
          value={duration.KLING}
          onChange={(_, value) => updateParams("KLING", "duration", value)}
        />
      </ColumnBox>
      <ColumnBox>
        {/* aspect */}
        <Typography variant="body2" mb={1}>
          {t("juno.video.params.aspect")}
        </Typography>
        <AspectRatioIcons aspectRatioList={aspectRatio} />
      </ColumnBox>
    </ColumnBox>
  );
};
export default ParamsKling;
