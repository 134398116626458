import React from "react";
import { Avatar, Box, Paper, Typography } from "@mui/material";
import { MarkdownContentBox } from "../../../components/common/MarkdownContentBox";
import CopyButtonOrIcon from "../../../components/common/CopyButtonOrIcon";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import ArticleIcon from "@mui/icons-material/Article";
import IconButton from "@mui/material/IconButton";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import Tooltip from "@mui/material/Tooltip";
import ShortTextIcon from "@mui/icons-material/ShortText";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";

export default function AnswerBox({ id, content }: { id?: string; content: string; processingId: string | null }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useCustomNavigate();

  const handleNavigate = (key: string) => {
    switch (key) {
      case "article":
        navigate("/writing-ai/article", { state: { text: content } });
        break;
      case "mindMap":
        navigate("/mind-map-generator", { state: { text: content } });
        break;
      case "summary":
        navigate("/writing-ai/summarizer", { state: { text: content } });
        break;
      default:
        break;
    }
  };

  return (
    <Paper
      data-id={id}
      elevation={theme.palette.mode === "dark" ? 2 : 0}
      sx={{
        px: { xs: 2, sm: 3 },
        pt: 2,
        pb: 2,
        mb: 1,
        transition: "all 0.3s ease",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "left",
          flexDirection: "row",
        }}
      >
        <Avatar alt="User avatar" src="/images/logo/logoZI-small.png" sx={{ width: 32, height: 32 }} />
        <Typography variant="subtitle1" component={"p"} sx={{ ml: 2 }} color={"textSecondary"}>
          {t("title")}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Box display="flex" justifyContent="right" alignItems={"flex-start"} gap={1}>
          <Tooltip title={t("browsing.article")} placement="top">
            <IconButton size="small" aria-label="article" onClick={() => handleNavigate("article")}>
              <ArticleIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("browsing.mindMap")} placement="top">
            <IconButton size="small" aria-label="article" onClick={() => handleNavigate("mindMap")}>
              <AccountTreeOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("browsing.summary")} placement="top">
            <IconButton size="small" aria-label="article" onClick={() => handleNavigate("summary")}>
              <ShortTextIcon />
            </IconButton>
          </Tooltip>

          <CopyButtonOrIcon displayType={"icon"} textToCopy={content} size={"small"} />
        </Box>
      </Box>
      <Box flexDirection={"column"} justifyContent={"left"} display={"flex"} sx={{ overflowWrap: "break-word" }} mt={1}>
        {content && content.length > 0 && <MarkdownContentBox>{content}</MarkdownContentBox>}
        {(!content || content.length === 0) && (
          <Typography variant="body2" textAlign="left" color={"error"} sx={{ fontWeight: "bold", ml: 2, mt: 2 }}>
            No answer
          </Typography>
        )}
      </Box>
    </Paper>
  );
}
