import React from "react";
import { useTranslation } from "react-i18next";
import { useParamsContext } from "../../../../../context/juno/ParamsContext";
import { FormControlLabel, FormGroup, Switch, Tooltip } from "@mui/material";

const RawMode = () => {
  const { t } = useTranslation();
  const { rawMode, setRawMode } = useParamsContext();

  return (
    <>
      <FormGroup>
        <Tooltip title={t("juno.input.rawModeDescription")} placement="top">
          <FormControlLabel
            control={<Switch checked={rawMode} onChange={() => setRawMode(!rawMode)} name="rawMode" color="primary" />}
            label={t("juno.input.rawMode")}
          />
        </Tooltip>
      </FormGroup>
    </>
  );
};
export default RawMode;
