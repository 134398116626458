import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ButtonBase, Fade, Typography } from "@mui/material";
import { RowCenteredBox } from "../../../../utils/styledBox";
import { AiEngineList, useVideo } from "../../../../context/juno/VideoContext";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { useJunoModels } from "../../../../hooks/useJunoModels";

const SelectMenu = () => {
  const { t } = useTranslation();
  const { menu, aiEngine, setAiEngine } = useVideo();
  const { getVideoModels } = useJunoModels();

  const ais = getVideoModels;

  // AIの選択
  const handleAiEngine = (value: AiEngineList) => {
    setAiEngine(value);
  };

  useEffect(() => {
    if (menu === "t2v" && aiEngine === "RUNWAY") {
      setAiEngine("LUMA");
    }
  }, [menu]);

  return (
    <>
      <RowCenteredBox sx={{ mb: 2 }}>
        <Typography variant="body2">- {t("juno.video.ai.select")} -</Typography>
      </RowCenteredBox>
      <Box sx={{ display: "flex", justifyContent: "center", gap: 2, flexDirection: { xs: "column", md: "row" } }}>
        {ais.map((item, index) => (
          <Fade in={!item.disabled.includes(menu)} key={index} timeout={(index + 1) * 250}>
            <Box key={item.value} sx={{ display: "flex", justifyContent: "center" }}>
              <ButtonBase
                onClick={() => handleAiEngine(item.value as AiEngineList)}
                sx={{
                  width: 200,
                  height: 65,
                  border: aiEngine === item.value ? "2px solid" : "2px solid transparent",
                  borderColor: aiEngine === item.value ? "primary.main" : "transparent",
                  boxSizing: "border-box",
                  display: "block",
                  borderRadius: 2,
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    backgroundImage: `url(images/juno/companies/${item.value.toLowerCase()}.png)`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    position: "relative",
                  }}
                  aria-label={item.label}
                >
                  <Tooltip title={item.description} placement="top">
                    <InfoIcon
                      sx={{
                        position: "absolute",
                        top: 6,
                        right: 6,
                        color: "white",
                        fontSize: 16,
                      }}
                    />
                  </Tooltip>
                </Box>
              </ButtonBase>
            </Box>
          </Fade>
        ))}
      </Box>
    </>
  );
};
export default SelectMenu;
