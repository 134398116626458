import React from "react";
import { Box } from "@mui/system";
import { ImageObject } from "../../../../../types/junoTypes";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

interface ImageLayersProps {
  images: ImageObject[];
  setImages: React.Dispatch<React.SetStateAction<ImageObject[]>>;
}

const ImageLayers = ({ images, setImages }: ImageLayersProps) => {
  const deleteImage = (id: string) => {
    setImages((prev) => prev.filter((image) => image.id !== id));
    // blobの場合、開放
    const image = images.find((image) => image.id === id);
    if (image) {
      URL.revokeObjectURL(image.image.src);
    }
  };

  return (
    <Box sx={{ height: "100%", overflow: "auto" }}>
      {images.map((image) => (
        <Box
          key={image.id}
          sx={{
            backgroundImage: `url(${image.image.src})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: 100,
            height: 100,
            border: 2,
            borderRadius: 2,
            mb: 1,
            position: "relative",
          }}
        >
          <IconButton
            sx={{ position: "absolute", top: 2, right: 2, color: "white" }}
            onClick={() => deleteImage(image.id)}
            size="small"
          >
            <CloseIcon fontSize={"small"} />
          </IconButton>
        </Box>
      ))}
    </Box>
  );
};

export default ImageLayers;
