import React from "react";
import { useLoginStatus } from "../../context/LoginStatusContext";
import { Fade, Hidden } from "@mui/material";
import { AvatarProvider } from "../../context/AvatarContext";
import Header from "./Header/Header";
import DrawerMain from "./Drawer/DrawerMain";
import Footer from "./Footer";
import Box from "@mui/material/Box";
import Notice from "../common/Notice";
import { LoginModalProvider } from "../../context/LoginModalContext";
import { styled } from "@mui/material/styles";
import MainRouter from "../../routes/MainRouter.tsx";
import ScrollToTopButton from "../common/ScrollToTopButton";
import { EventProvider } from "../../context/EventContext";
import HamburgerMenu from "./Header/HamburgerMenu";
import { Provider } from "react-redux";
import store from "../../redux/store";
import { NoticeProvider } from "../../context/NoticeContext";

const Layout = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <LoginModalProvider>
        <AvatarProvider>
          <EventProvider>
            <NoticeProvider>
              <Provider store={store}>
                <Header />
                <Contents />
                <Notice />
              </Provider>
            </NoticeProvider>
          </EventProvider>
        </AvatarProvider>
      </LoginModalProvider>
    </Box>
  );
};
export default Layout;

const Contents = () => {
  const { isLoggedIn } = useLoginStatus();

  return (
    <>
      <Box display="flex" flexDirection="row" flexGrow={1}>
        {isLoggedIn ? (
          <Fade in={true} timeout={1500}>
            <Box flexGrow={1}>
              <DrawerMain />
              <StyledDiv id={"main"}>
                <MainRouter />
                <Hidden smDown>
                  <ScrollToTopButton />
                </Hidden>
              </StyledDiv>
            </Box>
          </Fade>
        ) : (
          <Fade in={true} timeout={1500}>
            <Box flexGrow={1} display="flex" flexDirection="column">
              <Hidden lgUp>
                <HamburgerMenu />
              </Hidden>
              <StyledDiv id={"main"}>
                <MainRouter />
                <ScrollToTopButton />
              </StyledDiv>
            </Box>
          </Fade>
        )}
      </Box>
      <StyledDiv id={"footer"}>
        <Footer />
      </StyledDiv>
    </>
  );
};

const StyledDiv = styled("div")(({ theme }) => ({
  transition: theme.transitions.create(["margin", "transform", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: 0,
  width: "100vw",
}));
