import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Button, Divider, Drawer, IconButton, Typography, useMediaQuery } from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import { useTranslation } from "react-i18next";
import { useLoginModalContext } from "../../../context/LoginModalContext";
import { GradationButton } from "../../../utils/gradationButton";
import { ColumnCenteredBox } from "../../../utils/styledBox"; // Import ColumnCenteredBox
import LoginIcon from "@mui/icons-material/Login";
import { drawerWidth } from "../../../theme";
import ViewSidebarIcon from "@mui/icons-material/ViewSidebar";
import Home from "../Drawer/Home";
import { ArrowBackIos } from "@mui/icons-material";

const HamburgerMenu = () => {
  // 未ログインかつ、スマホ画面の場合のみDrawerを表示

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const navigate = useCustomNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const theme = useTheme();
  const loginModalContext = useLoginModalContext();

  const handleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const darkBackgroundColor1 =
    theme.palette.mode === "dark" ? theme.palette.grey[900] : theme.palette.background.default;
  const darkBackgroundColor2 = theme.palette.mode === "dark" ? theme.palette.grey[800] : theme.palette.background.paper;
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <>
      <IconButton
        aria-label="open drawer"
        onClick={handleDrawer}
        sx={{
          color: theme.palette.text.primary,
          position: "fixed",
          bottom: 16,
          left: 16,
          backgroundColor: (theme) => alpha(theme.palette.background.paper, 0.5),
          zIndex: 1300,
          display: isDrawerOpen ? "none" : "block",
        }}
      >
        <ViewSidebarIcon fontSize="small" />
      </IconButton>
      <Drawer
        anchor={isXs ? "bottom" : "left"}
        open={isDrawerOpen}
        onClose={handleDrawer}
        sx={{
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: { xs: "100%", sm: drawerWidth },
            boxSizing: "border-box",
            backgroundColor: alpha(
              theme.palette.mode === "dark" ? theme.palette.background.default : theme.palette.background.custom1,
              0.6
            ),
            borderRight: "none",
            top: "56px",
            height: "calc(100% - 56px)",
          },
        }}
      >
        <ColumnCenteredBox
          sx={{
            backgroundColor: darkBackgroundColor2,
            backdropFilter: "blur(10px)", // ぼかし効果
            opacity: 0.9, // 透過効果
            px: 2,
            py: 3,
            height: "100%",
            gap: 1,
            minWidth: 200,
            position: "relative",
          }}
        >
          <IconButton onClick={handleDrawer} sx={{ position: "absolute", top: 8, right: 8 }}>
            <ArrowBackIos fontSize="small" />
          </IconButton>

          <Typography variant={"h6"} sx={{ color: theme.palette.text.secondary, fontWeight: 700 }}>
            MENU
          </Typography>
          <Divider sx={{ width: "100%", my: 1 }} />
          <Home />
          <Divider sx={{ width: "100%", my: 1 }} />
          <Button
            onClick={() => {
              navigate("/pricing");
              handleDrawer();
            }}
            variant={"text"}
            sx={{
              color: location.pathname.split("/")[2] === "pricing" ? "primary.light" : theme.palette.text.secondary,
            }}
          >
            <Typography fontWeight={location.pathname.split("/")[2] === "pricing" ? 700 : 400} variant={"button"}>
              {t("pricing.title")}
            </Typography>
          </Button>

          <Box sx={{ flexGrow: 1 }} />
        </ColumnCenteredBox>
        <Box
          sx={{
            backgroundColor: darkBackgroundColor1,
            backdropFilter: "blur(10px)", // ぼかし効果
            opacity: 0.9, // 透過効果
            px: 2,
            py: 8,
          }}
        >
          <Button
            startIcon={<LoginIcon />}
            variant="contained"
            disableElevation
            color="primary"
            onClick={() => loginModalContext.setActiveModal("login")}
            sx={{ mb: 1, width: "100%" }}
          >
            {t("top.login")}
          </Button>
          <GradationButton
            color="inherit"
            onClick={() => loginModalContext.setActiveModal("signup")}
            sx={{ width: "100%" }}
          >
            {t("top.signupHeader")}
          </GradationButton>
        </Box>
      </Drawer>
    </>
  );
};

export default HamburgerMenu;
