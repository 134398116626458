import Quill from "quill";
import Delta from "quill-delta";
import React, { useState } from "react";
import { Box, Button, IconButton, Modal, Paper, TextField, Tooltip, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { useAlert } from "../../../../context/AlertContext";
import { RowCenteredBox } from "../../../../utils/styledBox";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";

interface QuillInterface extends Quill {
  import: (path: string) => any;
}

type VideoEmbedProps = {
  quill: Quill | null;
};

type Range = {
  index: number;
  length: number;
};

const QuillClass = Quill as unknown as QuillInterface;
const BlockEmbed = QuillClass.import("blots/block/embed");

export class VideoEmbedBlot extends BlockEmbed {
  static blotName = "video-embed";
  static tagName = "div";
  static className = "video-embed";

  static create(value: any) {
    const node = super.create();
    const iframe = document.createElement("iframe");
    iframe.setAttribute("width", "100%");
    iframe.setAttribute("height", "400");
    iframe.setAttribute("frameborder", "0");
    iframe.setAttribute("scrolling", "no");
    iframe.setAttribute(
      "allow",
      "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    );
    iframe.setAttribute("allowfullscreen", "true");
    iframe.src = VideoEmbedBlot.extractEmbedUrl(value.url);
    node.appendChild(iframe);
    return node;
  }

  static value(node: HTMLElement) {
    const iframe = node.querySelector("iframe");
    return {
      url: iframe ? iframe.src : "",
    };
  }

  static extractEmbedUrl(url: string): string {
    // Extracts the embed URL for YouTube and Vimeo videos
    if (url.includes("youtube.com") || url.includes("youtu.be")) {
      // Regex to match YouTube video IDs, including regular YouTube links, youtu.be links, and shorts links
      const regExp =
        /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/|youtube\.com\/shorts\/)([a-zA-Z0-9_-]{11})/;
      const match = url.match(regExp);
      // If a match is found, return the embed URL format
      return match ? `https://www.youtube.com/embed/${match[1]}` : url;
    } else if (url.includes("vimeo.com")) {
      // Regex to match Vimeo video IDs
      const regExp = /(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(\d+)/;
      const match = url.match(regExp);
      // If a match is found, return the embed URL format
      return match ? `https://player.vimeo.com/video/${match[1]}` : url;
    } else if (url.includes(".mp4") || url.includes(".webm") || url.includes(".ogg")) {
      // Return original URL for supported video file formats
      return url;
    }
    // Default return if no match
    return "";
  }
}

const VideoEmbed: React.FC<VideoEmbedProps> = ({ quill }) => {
  const [videoUrl, setVideoUrl] = useState("");
  const [embedModalOpen, setEmbedModalOpen] = useState(false);
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const [savedRange, setSavedRange] = useState<Range | null>(null);

  const handleClick = () => {
    if (quill) {
      const range = quill.getSelection();
      if (range) {
        setSavedRange(range);
      }
    }
    setEmbedModalOpen(true);
  };

  const insertVideoEmbed = () => {
    if (!savedRange || !quill) return;
    const index = savedRange.index;
    const videoUrlEmbed = VideoEmbedBlot.extractEmbedUrl(videoUrl);
    console.log(videoUrlEmbed);
    if (!videoUrlEmbed) {
      setAlert("error", "Invalid video URL");
      return;
    }

    const delta = new Delta().retain(index).insert({ "video-embed": { url: videoUrl } });
    quill.updateContents(delta, "user");
    quill.setSelection(index + 1, 0);
    setVideoUrl("");
    setEmbedModalOpen(false);
  };

  return (
    <>
      <Tooltip title={t("textEditor.toolbars.video")}>
        <button onClick={handleClick}>
          <OndemandVideoIcon fontSize={"small"} className={"ql-fill"} />
        </button>
      </Tooltip>

      <Modal open={embedModalOpen} onClose={() => setEmbedModalOpen(false)}>
        <Paper sx={{ p: 2, maxWidth: 400, margin: "auto", mt: 10, position: "relative" }}>
          <IconButton
            onClick={() => setEmbedModalOpen(false)}
            sx={{ position: "absolute", top: 5, right: 5 }}
            size="small"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
            <RowCenteredBox mb={2}>
              <OndemandVideoIcon color="primary" fontSize={"large"} sx={{ mr: 1 }} />
              <Typography variant="h6">Video Embed</Typography>
            </RowCenteredBox>
            <TextField
              label="YouTube or Video URL"
              placeholder="https://www.youtube.com/watch?v=example"
              value={videoUrl}
              onChange={(e) => setVideoUrl(e.target.value)}
              InputLabelProps={{ shrink: true }}
              autoFocus
            />
            <Button variant="contained" color="primary" onClick={insertVideoEmbed}>
              Insert Video
            </Button>
          </Box>
        </Paper>
      </Modal>
    </>
  );
};

export default VideoEmbed;
