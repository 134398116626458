import ReactFlow, {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Background,
  Connection,
  Controls,
  Edge,
  getConnectedEdges,
  getIncomers,
  getOutgoers,
  Node,
  OnConnect,
  ReactFlowInstance,
  reconnectEdge,
} from "reactflow";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useMindMap } from "../../../context/MindmapContext";
import CustomNode from "./CustomNode";
import { useTranslation } from "react-i18next";
import { useAlert } from "../../../context/AlertContext";
import { useParams } from "react-router-dom";

const nodeTypes = { custom: CustomNode };

const MindMap = () => {
  const reactFlowWrapper = useRef<HTMLDivElement | null>(null);
  const edgeUpdateSuccessful = useRef(true);
  const snapGrid = [10, 10];
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const { mindMapUuid } = useParams<{ mindMapUuid: string }>();

  const {
    nodes: contextNodes,
    edges: contextEdges,
    setNodes: setContextNodes,
    setEdges: setContextEdges,
    setReactFlowInstance,
    actionTrigger,
    getMindMap,
    autoLayout,
    initialSave,
    animation,
    edgeType,
    setEdgeType,
    nodeDefaults,
    setTitle,
    status,
    setStatus,
    setAnimation,
  } = useMindMap();

  // ローカル状態でノードとエッジを管理
  const [nodes, setNodesLocal] = useState<Node[]>([]);
  const [edges, setEdgesLocal] = useState<Edge[]>([]);

  // コンテキストからローカル状態に同期
  useEffect(() => {
    setNodesLocal(contextNodes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextNodes]);

  useEffect(() => {
    setEdgesLocal(contextEdges);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextEdges]);

  // ノード削除アクションの監視
  useEffect(() => {
    if (actionTrigger.node_action === "delete") {
      onDelete(actionTrigger.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionTrigger.triggerFetch]);

  // ノード削除の処理
  const onDelete = useCallback(
    (id: string) => {
      if (nodes.length === 1) {
        setAlert("error", t("mindmap.alert.notDeletedNode"));
        return;
      }

      const nodeToDelete = nodes.find((node) => node.id === id);
      if (!nodeToDelete) return;

      const incomers = getIncomers(nodeToDelete, nodes, edges);
      const outgoers = getOutgoers(nodeToDelete, nodes, edges);

      const updatedEdges = edges.filter((edge) => edge.source !== id && edge.target !== id);

      const createdEdges = incomers.flatMap(({ id: source }) =>
        outgoers.map(({ id: target }) => ({
          id: `${source}->${target}`,
          source,
          target,
          animated: animation,
          type: edgeType,
        }))
      );

      const newEdges = [...updatedEdges, ...createdEdges];
      const newNodes = nodes.filter((node) => node.id !== id);

      setEdgesLocal(newEdges);
      setNodesLocal(newNodes);

      // コンテキストに更新
      setContextEdges(newEdges);
      setContextNodes(newNodes);
    },
    [nodes, edges, animation, edgeType, setAlert, t, setContextEdges, setContextNodes]
  );

  // ReactFlowの初期化
  const onLoad = useCallback(
    (instance: ReactFlowInstance) => {
      setReactFlowInstance(instance);
      instance?.fitView();
    },
    [setReactFlowInstance]
  );

  // ノード間の接続処理
  const onConnect: OnConnect = useCallback(
    (connection) => {
      const newEdges = addEdge(connection, edges);
      setEdgesLocal(newEdges);
      setContextEdges(newEdges); // コンテキストに更新
    },
    [edges, setContextEdges]
  );

  // ノード削除時のエッジ処理
  const onNodesDelete = useCallback(
    (deleted: Node[]) => {
      const newEdges = deleted.reduce((acc, node) => {
        const incomers = getIncomers(node, nodes, acc);
        const outgoers = getOutgoers(node, nodes, acc);
        const connectedEdges = getConnectedEdges([node], acc);

        const remainingEdges = acc.filter((edge) => !connectedEdges.includes(edge));

        const createdEdges = incomers.flatMap(({ id: source }) =>
          outgoers.map(({ id: target }) => ({
            id: `${source}->${target}`,
            source,
            target,
            animated: animation,
            type: edgeType,
          }))
        );

        return [...remainingEdges, ...createdEdges];
      }, edges);

      const newNodes = nodes.filter((n) => !deleted.find((d) => d.id === n.id));

      setEdgesLocal(newEdges);
      setNodesLocal(newNodes);

      // コンテキストに更新
      setContextEdges(newEdges);
      setContextNodes(newNodes);
    },
    [nodes, edges, animation, edgeType, setContextEdges, setContextNodes]
  );

  // エッジ更新開始時の処理
  const onEdgeUpdateStart = useCallback(() => {
    edgeUpdateSuccessful.current = false;
  }, []);

  // エッジ更新時の処理
  const onEdgeUpdate = useCallback(
    (oldEdge: Edge, newConnection: Connection) => {
      edgeUpdateSuccessful.current = true;
      const newEdges = reconnectEdge(oldEdge, newConnection, edges);
      setEdgesLocal(newEdges);
      // コンテキストの更新は必要なタイミングで行う
    },
    [edges]
  );

  // エッジ更新終了時の処理
  const onEdgeUpdateEnd = useCallback(
    (_: any, edge: Edge) => {
      if (!edgeUpdateSuccessful.current) {
        const newEdges = edges.filter((e) => e.id !== edge.id);
        setEdgesLocal(newEdges);
        // コンテキストの更新は必要なタイミングで行う
      }
    },
    [edges]
  );

  const [noPosition, setNoPosition] = useState(false);
  // ノードの位置が未設定の場合、自動レイアウトを実行
  useEffect(() => {
    if (noPosition && nodes[0]?.data.size) {
      autoLayout();
      initialSave(nodes);
      setNoPosition(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noPosition, nodes]);

  // MindMap生成中のデータ取得
  useEffect(() => {
    if (status !== 3) return;

    const timer = setInterval(() => {
      getMindMap();
    }, 5000);

    return () => clearInterval(timer);
  }, [status, getMindMap]);

  // MindMapデータの取得
  useEffect(() => {
    const fetchMindMap = async () => {
      const data = await getMindMap();
      if (data) {
        setTitle(data.title);
        setStatus(data.status);

        if (data.nodes.length === 0) {
          const defaultNode = {
            id: "1",
            type: "custom",
            position: { x: 0, y: 0 },
            data: {
              label: "Hello, World!",
              color: "",
              size: { width: 200, height: 100 },
              links: [],
              images: [],
            },
            width: 200,
            height: 100,
            ...nodeDefaults,
          };
          setNodesLocal([defaultNode]);
          setContextNodes([defaultNode]); // コンテキストにも反映
        } else {
          setNodesLocal(data.nodes);
          setContextNodes(data.nodes); // コンテキストにも反映
          if (data.nodes.length > 0 && !data.nodes[0].position.x && !data.nodes[0].position.y) {
            setNoPosition(true);
          }
        }
        setEdgesLocal(data.edges);
        setContextEdges(data.edges); // コンテキストにも反映
        setEdgeType(data.edges[0]?.type || "default");
        setAnimation(data.edges[0]?.animated || false);
      }
    };
    fetchMindMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mindMapUuid]);

  // ノードの変更をローカルで管理
  const onNodesChange = useCallback(
    (changes) => {
      const updatedNodes = applyNodeChanges(changes, nodes);
      setNodesLocal(updatedNodes);
      // コンテキストの更新はここでは行わない
    },
    [nodes]
  );

  // エッジの変更をローカルで管理
  const onEdgesChange = useCallback(
    (changes) => {
      const updatedEdges = applyEdgeChanges(changes, edges);
      setEdgesLocal(updatedEdges);
      // コンテキストの更新はここでは行わない
    },
    [edges]
  );

  // ノードドラッグ終了時にコンテキストを更新
  const onNodeDragStop = useCallback(
    (event, node) => {
      // const updatedNodes = nodes.map((n) => (n.id === node.id ? node : n));
      // setNodesLocal(updatedNodes);

      // コンテキストを更新
      setContextNodes(nodes);
    },
    [nodes, setContextNodes]
  );

  // エッジ更新終了時にコンテキストを更新（必要に応じて）
  const onEdgeUpdateEndFinal = useCallback(() => {
    // コンテキストを更新
    setContextEdges(edges);
  }, [edges, setContextEdges]);

  return (
    <div ref={reactFlowWrapper} style={{ width: "100%", height: "100%" }}>
      <ReactFlow
        id="reactflow"
        nodes={nodes}
        nodeTypes={nodeTypes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onNodeDragStop={onNodeDragStop}
        onNodesDelete={onNodesDelete}
        onEdgeUpdate={onEdgeUpdate}
        onEdgeUpdateEnd={onEdgeUpdateEnd}
        onEdgeUpdateStart={onEdgeUpdateStart}
        onConnect={onConnect}
        onInit={onLoad}
        fitView
        maxZoom={1.5}
        minZoom={0.1}
        snapToGrid={true}
        snapGrid={[snapGrid[0], snapGrid[1]]}
        proOptions={{ hideAttribution: true }}
        style={{ width: "100%", height: "100%" }}
      >
        <Background />
        <Controls />
      </ReactFlow>
    </div>
  );
};

export default MindMap;
