import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { ColumnBox, RowBox } from "../../../../utils/styledBox";
import SlideshowIcon from "@mui/icons-material/Slideshow";

const SampleVideoList = () => {
  const { t } = useTranslation();
  const [playingVideo, setPlayingVideo] = useState(null);

  const videoList = [
    {
      value: "runway",
      alt: t("juno.video.ai.runway.title"),
      video: "/videos/juno/runway.mp4",
      thumbnail: "/images/juno/companies/runway-thumbnail.jpg",
    },
    {
      value: "kling",
      alt: t("juno.video.ai.kling.title"),
      video: "/videos/juno/kling.mp4",
      thumbnail: "/images/juno/companies/kling-thumbnail.jpg",
    },
    {
      value: "luma",
      alt: t("juno.video.ai.luma.title"),
      video: "/videos/juno/luma.mp4",
      thumbnail: "/images/juno/companies/luma-thumbnail.jpg",
    },
    {
      value: "hailuo",
      alt: t("juno.video.ai.hailuo.title"),
      video: "/videos/juno/hailuo.mp4",
      thumbnail: "/images/juno/companies/hailuo-thumbnail.jpg",
    },
  ];

  const handlePlayVideo = (value) => {
    setPlayingVideo(value);
  };

  return (
    <ColumnBox>
      <RowBox mb={4} sx={{ width: "100%" }}>
        <SlideshowIcon color={"secondary"} sx={{ mr: 1 }} />
        <Typography variant="subtitle1">{t("juno.video.sampleVideo")}</Typography>
      </RowBox>
      <Grid container spacing={2}>
        {videoList.map((video) => (
          <Grid item key={video.value} xs={12} sm={6} md={4}>
            <Box sx={{ borderRadius: 2, overflow: "hidden" }}>
              {playingVideo === video.value ? (
                <video width="100%" controls autoPlay style={{ borderRadius: "8px", overflow: "hidden" }}>
                  <source src={video.video} type="video/mp4" />
                  {t("juno.video.error")}
                </video>
              ) : (
                <Box style={{ position: "relative", cursor: "pointer" }} onClick={() => handlePlayVideo(video.value)}>
                  <img
                    src={video.thumbnail}
                    alt={video.alt}
                    width="100%"
                    style={{ borderRadius: "8px", overflow: "hidden" }}
                  />
                  <IconButton
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -60%)",
                      color: "white",
                      fontSize: 60,
                    }}
                    size="large"
                    aria-label="play"
                  >
                    <PlayCircleOutlineIcon fontSize="large" />
                  </IconButton>
                </Box>
              )}
            </Box>
          </Grid>
        ))}
      </Grid>
    </ColumnBox>
  );
};

export default SampleVideoList;
