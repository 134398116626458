import Quill from "quill";
import Delta from "quill-delta";
import React, { useState } from "react";
import { Box, Button, IconButton, Modal, Paper, TextField, Tooltip, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { useAlert } from "../../../../context/AlertContext";
import { RowCenteredBox } from "../../../../utils/styledBox";
import XIcon from "@mui/icons-material/X";

interface QuillInterface extends Quill {
  import: (path: string) => any;
}

type TwitterEmbedProps = {
  quill: Quill | null;
};

type Range = {
  index: number;
  length: number;
};

const QuillClass = Quill as unknown as QuillInterface;
const BlockEmbed = QuillClass.import("blots/block/embed");

export class TwitterEmbedBlot extends BlockEmbed {
  static blotName = "twitter-embed";
  static tagName = "div";
  static className = "twitter-embed";

  static create(value: any) {
    const node = super.create();

    // Twitter埋め込み全体を包むdiv
    const wrapper = document.createElement("div");
    wrapper.className = "twitter-embed-wrapper";

    // アイコンのためのdiv要素を作成
    const iconDiv = document.createElement("div");
    iconDiv.className = "twitter-icon";
    iconDiv.innerHTML =
      '<svg ><g><path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path></g></svg>';

    // 名前と日付を表示するためのdiv要素を作成
    const contentDiv = document.createElement("div");
    contentDiv.className = "twitter-content";

    // 名前を表示
    const name = document.createElement("div");
    name.className = "twitter-name";
    name.textContent = value.author || "User";

    contentDiv.appendChild(name);

    // x.com表示用のdiv要素を作成
    const footerDiv = document.createElement("div");
    footerDiv.className = "twitter-footer";
    footerDiv.textContent = "x.com";

    // 各要素をnodeに追加
    wrapper.appendChild(iconDiv);
    wrapper.appendChild(contentDiv);
    node.appendChild(wrapper);

    // レスポンスから取得したHTMLを先に挿入
    node.innerHTML += value.html;

    // フッターを後に挿入
    node.appendChild(footerDiv);

    return node;
  }

  static value(node: HTMLElement) {
    return {
      html: node.innerHTML,
    };
  }

  static extractEmbedUrl(url: string): string {
    const regExp = /(?:https?:\/\/)?(?:www\.)?x\.com\/[^\/]+\/status\/([0-9]+)/;
    const match = url.match(regExp);
    return match ? `https://x.com/${match[0]}` : "";
  }
}

const TwitterEmbed: React.FC<TwitterEmbedProps> = ({ quill }) => {
  const [tweetUrl, setTweetUrl] = useState("");
  const [embedModalOpen, setEmbedModalOpen] = useState(false);
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const [savedRange, setSavedRange] = useState<Range | null>(null);

  // クリックイベントを処理してモーダルを開き、現在の選択範囲を保存する
  const handleClick = () => {
    if (quill) {
      const range = quill.getSelection();
      if (range) {
        setSavedRange(range);
      }
    }
    setEmbedModalOpen(true);
  };

  // 保存された選択範囲に Twitter 埋め込みを挿入する
  const insertTwitterEmbed = async () => {
    if (!savedRange || !quill) return;

    const index = savedRange.index;
    const embedUrl = TwitterEmbedBlot.extractEmbedUrl(tweetUrl);
    if (!embedUrl) {
      setAlert("error", "Invalid Twitter URL"); // URL が無効な場合はアラートを表示
      return;
    }

    try {
      // サーバーサイドでTwitterのoEmbedを取得するプロキシエンドポイントを使用する
      const response = await fetch(`/api/v1/text-file/twitter-embed?url=${encodeURIComponent(tweetUrl)}`);

      if (!response.ok) {
        throw new Error("Twitter embed retrieval failed");
      }

      const data = await response.json();

      // 指定したインデックスに Twitter 埋め込みを挿入するための Delta を作成
      const delta = new Delta()
        .retain(index)
        .insert({ "twitter-embed": { html: data.html, author: data.author, authorUrl: data.author_url } });
      quill.updateContents(delta, "user"); // エディタの内容を更新
      quill.setSelection(index + 1, 0); // 挿入後のカーソル位置を設定
      setTweetUrl(""); // 入力フィールドをクリア
      setEmbedModalOpen(false); // モーダルを閉じる
    } catch (error) {
      setAlert("error", "Failed to retrieve Twitter embed");
    }
  };

  return (
    <>
      <Tooltip title={t("textEditor.toolbars.twitter")}>
        <button onClick={handleClick}>
          <XIcon fontSize={"small"} className={"ql-fill"} />
        </button>
      </Tooltip>

      <Modal open={embedModalOpen} onClose={() => setEmbedModalOpen(false)}>
        <Paper sx={{ p: 2, maxWidth: 400, margin: "auto", mt: 10, position: "relative" }}>
          <IconButton
            onClick={() => setEmbedModalOpen(false)}
            sx={{ position: "absolute", top: 5, right: 5 }}
            size="small"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
            <RowCenteredBox mb={2}>
              <XIcon color="primary" fontSize={"large"} sx={{ mr: 1 }} />
              <Typography variant="h6">Twitter 埋め込み</Typography>
            </RowCenteredBox>
            <TextField
              label="Twitter Post URL"
              placeholder="https://x.com/user/status/example"
              value={tweetUrl}
              onChange={(e) => setTweetUrl(e.target.value)}
              InputLabelProps={{ shrink: true }}
              autoFocus
            />
            <Button variant="contained" color="primary" onClick={insertTwitterEmbed}>
              Twitter 投稿を挿入
            </Button>
          </Box>
        </Paper>
      </Modal>
    </>
  );
};

export default TwitterEmbed;
