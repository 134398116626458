import { Fade, Stack, Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import ScrollableBox from "../../../../components/common/ScrollableBox";
import { useJunoModels } from "../../../../hooks/useJunoModels";
import { AiEngineList, MenuList } from "../../../../types/junoTypes";
import AspectRatio from "./params/AspectRatio";
import Style from "./params/Style";
import SelectPublic from "./SelectPublic";
import React from "react";
import ExpandPrompt from "./params/ExpandPrompt";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import { useTranslation } from "react-i18next";
import Sample from "./params/Samples";
import StyleStrength from "./params/StyleStrength";
import Steps from "./params/Steps";
import RawMode from "./params/RawMode";
import GuidanceScale from "./params/GuidanceScale";
import Seed from "./params/Seed";
import Image from "./params/Image";
import ImageStrength from "./params/ImageStrength";
import NegativePrompt from "./params/NegativePrompt";
import Colors from "./params/Colors";
import Upscale from "./params/Upscale";
import ImageEditor from "./params/ImageEditor";

const ParamsManager = () => {
  const { t } = useTranslation();
  const params = new URLSearchParams(window.location.search);
  const aiEngine = params.get("ai_engine");
  const menu = params.get("menu");
  const theme = useTheme();
  const { getModelParams } = useJunoModels();
  if (!aiEngine) return null;
  const modelParams = getModelParams(aiEngine as AiEngineList, menu as MenuList);

  return (
    <ScrollableBox
      component={Paper}
      sx={{ height: "100%", overflowY: "auto", borderRadius: 1 }}
      elevation={theme.palette.mode === "dark" ? 1 : 0}
    >
      {aiEngine ? (
        <Fade in={true}>
          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              width: "100%",
              p: 2,
              whiteSpace: "nowrap",
            }}
          >
            <Stack spacing={3}>
              <Box display={"flex"} flexDirection={"row"} alignItems={"center"} sx={{ mb: 2 }}>
                <SettingsSuggestIcon sx={{ mr: 1 }} />
                <Typography variant={"subtitle1"} component={"p"}>
                  {t("juno.input.basicSettings")}
                </Typography>
              </Box>

              {["txt2img", "img2img", "editor"].includes(menu!) && (
                <>
                  {modelParams.includes("sample") && <Sample />}
                  {modelParams.includes("aspectRatio") && <AspectRatio />}
                  {modelParams.includes("initImage") && <Image />}
                  {modelParams.includes("editor") && <ImageEditor />}
                  {modelParams.includes("imageStrength") && <ImageStrength />}
                  {modelParams.includes("style") && <Style />}
                  {modelParams.includes("styleWeight") && <StyleStrength />}
                  {modelParams.includes("colors") && <Colors />}
                  {modelParams.includes("rawMode") && <RawMode />}
                  {modelParams.includes("step") && <Steps />}
                  {modelParams.includes("revisedPrompt") && <ExpandPrompt />}
                  {modelParams.includes("guidanceScale") && <GuidanceScale />}
                  {modelParams.includes("negativePrompt") && <NegativePrompt />}
                  {modelParams.includes("seed") && <Seed />}
                  <SelectPublic />
                </>
              )}
              {["upscale"].includes(menu!) && <Upscale />}
            </Stack>

            <Box flexGrow={1} />
          </Box>
        </Fade>
      ) : (
        <Box
          sx={{
            justifyContent: "center",
            display: "flex",
            width: "100%",
            height: "100%",
            alignItems: "center",
          }}
        >
          <Box>
            <CircularProgress />
          </Box>
        </Box>
      )}
    </ScrollableBox>
  );
};
export default ParamsManager;
